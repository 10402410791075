<template>
  <main>
    <div
      @click.self="hideBackDrop ? undefined : clickOutSide()"
      :class="[
        center ? 'flex items-center justify-center' : undefined,
        hideBackDrop ? 'nobg' : 'bg',
        noclick ? 'pointer-events-none' : undefined,
      ]"
      style="z-index: 999"
      class="inset-0 w-full overflow-y-auto py-16"
    >
      <div v-if="lite" class="absolute right-0 top-0 mt-3 mx-3 cursor-pointer" @click="close">
        <i class="fas fa-times text-2xl hover:text-white"></i>
      </div>

      <div
        :class="[
          `rounded-${rounded}`,
          lite ? 'bg-none' : reward ? 'backgroundG' : 'bg-white',

          size
            ? `md:max-w-${size}`
            : terms
            ? 'max-w-5xl'
            : compliance
            ? 'md:max-w-3xl'
            : payroll
            ? 'md:max-w-3xl'
            : apiDashboard
            ? 'md:max-w-lg'
            : 'md:max-w-md',
        ]"
        :style="{
          borderRadius: pixelRounded,
        }"
        class="z-20 md:w-full w-11/12 mx-auto pb-4 shadow-xs"
      >
        <div v-if="showBackButton" @click="back" class="float-left mt-3 mx-3 bg-newAsh p-3 rounded-xl cursor-pointer">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.25 7L0.75 7M0.75 7L6.375 12.625M0.75 7L6.375 1.375"
              stroke="#11453B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          v-if="!lite && showCloseButton"
          @click="close"
          class="float-right flex items-center flex-col justify-center mt-3 mx-3 bg-newAsh p-2 rounded-xl cursor-pointer"
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="28" height="28" rx="8" />
            <path
              d="M5 15L15 5M5 5L15 15"
              stroke="#292A29"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div v-if="isHeader" class="w-full flex justify-center items-center px-6 pb-2 text-2xl">
          <slot name="header" />
        </div>
        <hr v-if="isBorder && isHeader && !lite" class="mt-2 mb-4 border-0.5 border-dividerColor text-dividerColor" />

        <main>
          <div class="px-0">
            <slot />
          </div>

          <!-- <slot /> -->
        </main>
      </div>
    </div>
  </main>
</template>

<script setup>
import { onUnmounted } from "@vue/runtime-core";

const props = defineProps({
  showBackDrop: {
    type: Boolean,
    default: true,
  },
  hideBackDrop: {
    type: Boolean,
    default: false,
  },
  reward: {
    type: Boolean,
    default: false,
  },
  noclick: {
    type: Boolean,
    default: false,
  },
  isHeader: {
    type: Boolean,
    default: true,
  },
  isBorder: {
    type: Boolean,
    default: true,
  },
  apiDashboard: {
    type: Boolean,
    default: false,
  },
  compliance: {
    type: Boolean,
    default: false,
  },
  payroll: {
    type: Boolean,
    default: false,
  },

  center: {
    type: Boolean,
    default: false,
  },
  lite: {
    type: Boolean,
    default: false,
  },
  terms: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: String,
    default: "3xl",
  },
  color: {
    type: String,
    default: "bg-background-100",
  },
  size: {
    type: String,
    default: "",
  },
  persistence: {
    type: Boolean,
    default: true,
  },
  showCloseButton: {
    type: Boolean,
    default: true,
  },
  showBackButton: {
    type: Boolean,
    default: false,
  },
  // size: {
  //   type: String,
  //   default: "",
  // },
  pixelRounded: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update", "close", "back", "outside"]);

function close() {
  emit("close");
}

function back() {
  emit("back");
}

const clickOutSide = () => {
  if (!props.persistence) {
    close();
  } else {
    emit("outside");
  }
};
</script>

<style scoped>
.widt {
  width: 80% !important;
}
.bg {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 19, 9, 0.597);
}
.nobg {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}

.backgroundG {
  background: url("../../assets/img/reward1.webp");
  width: 358px;
  height: 264px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #11453b;
  position: relative;
  /* inset: 0; */
}
</style>
