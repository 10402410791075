<template>
  <RouterLink v-if="isLink" :to="to" :class="classNames">
    <slot />
  </RouterLink>
  <button v-else :class="classNames" :disabled="$attrs.disabled || isLoading">
    <div class="flex items-center gap-2">
      <div class="btn-spinner" v-if="isLoading"></div>
      <div>
        <slot />
      </div>
    </div>
  </button>
</template>

<script setup>
import { computed } from "vue";
import { RouterLink } from "vue-router";

const props = defineProps({
  to: String,
  color: {
    type: String,
    default: "primary",
  },
  shadow: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const classNames = computed(() => {
  let classNames = ["btn", props.color];
  if (props.shadow) {
    classNames.push("shadow");
  }

  return classNames.join(" ");
});

const isLink = computed(() => props.to && props.to.length > 0);
</script>

<style lang="scss" scoped>
.btn {
  outline: none;
  height: 46px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 500;
  border-radius: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.25s ease;
  &:not(:disabled):hover {
    transform: translateY(-1px);
  }

  &:disabled {
    opacity: 0.6;
  }
  &.primary {
    background: #11453b;
    color: white;

    &.shadow {
      box-shadow: 0px 8px 16px rgba(17, 69, 59, 0.2);
    }
  }

  &.secondary {
    background: #292a29;
    color: white;

    &.shadow {
      box-shadow: 0px 8px 16px rgba(17, 69, 59, 0.2);
    }
  }
  &.tertiary {
    background: rgba(17, 69, 59, 0.1);
    color: #11453b;

    // &.shadow {
    //   box-shadow: 0px 8px 16px rgba(17, 69, 59, 0.2);
    // }
  }
}

.btn-spinner {
  height: 15px;
  width: 15px;
  border: 1.4px solid white;
  border-top-color: transparent;
  animation: spin 0.5s linear infinite;
  border-radius: 99px;
  transition: width 0.25s ease;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
