<template>
  <div class="md:w-9/12 w-full mx-auto mt-8 grid grid-cols-1 gap-3">
    <div
      v-for="(faq, i) in visibleData"
      :key="i + 1"
      class="relative cursor-pointer mb-1 w-full"
      @click="toggleAccordion(i + 1)"
    >
      <input type="checkbox" :checked="expanded[i + 1]" :id="`toggle${i + 1}`" class="toggle hidden" />
      <h1
        class="title block text-base text-white font-bold cursor-pointer"
        :for="`toggle${i + 1}`"
        :class="expanded[i + 1] ? 'bg-dark-800 pad' : 'full-pad backgrd'"
      >
        {{ faq.question }}
      </h1>

      <div
        v-show="expanded[i + 1]"
        class="dropdown_menu-6 content overflow-hidden text-white rounded-b-3xl -mt-4 z-10"
        :class="expanded[i + 1] ? ' bg-dark-800' : 'backgrd'"
      >
        <p v-html="faq.answer" style="padding-left: 24px; padding-right: 24px" class="pb-4 text-sm leading-7"></p>
      </div>

      <div class="bg-transparent absolute right-1 mt-2 top-4 w-10 pointer-events-none">
        <div v-show="expanded[i + 1]" class="focus:outline-none bg-none">
          <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.25 1.5L8.75 9L1.25 16.5"
              stroke="#E3E5E5"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div v-show="!expanded[i + 1]" class="focus:outline-none bg-none">
          <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.5 1.25L9 8.75L1.5 1.25"
              stroke="#8D8E8D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>

    <button
      class="bg-none focus:outline-none text-right text-sm text-white"
      @click="seeMore(faqs.length === visibleData.length)"
    >
      {{ faqs.length === visibleData.length ? "See less" : "See more" }}
    </button>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";

const expanded = ref({
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
  7: false,
  8: false,
  9: false,
  10: false,
  11: false,
});
const visibleData = ref([]);
const faqs = ref([
  {
    question: "What does Eazipay offer?",
    answer:
      "Eazipay streamlines your Payments, HR, and IT. It is very easy to make bulk payments such as salaries, PAYE, and Pension. With Eazipay, hiring graduates and assessing prospective employees with world-class questions has been made easy. We offer this and many more benefits that help businesses succeed, that is why <strong>audacious</strong> businesses use Eazipay.",
  },
  {
    question: "What are the pricing plans for using Eazipay?",
    answer:
      "The basic plan ensures that your business pays transaction fees for paying salaries only. For more information on the pricing, <a style='font-weight: 700' href='/login'>click here.</a>",
  },

  {
    question: "How secure is Eazipay?",
    answer:
      "Security is paramount at Eazipay. We have implemented good standards to ensure robust protection for our customers and user-based security features like 2FA, biometrics, and other verifications are strongly in place. Our annual audits also ensure that we are NDPR compliant. For more information, please see our security page here.",
  },
  {
    question: "Can I use Eazipay if my business is not registered yet?",
    answer:
      "Yes, you can use Eazipay as an individual or unregistered business. KYC requirements for opening an individual account apply.",
  },
  {
    question: "Can I use Eazipay immediately?",
    answer:
      "Yes, you can use Eazipay immediately after account registration and filling your basic KYC requirements. Inflows into your Eazipay wallet are also instant.",
  },
  {
    question: "What if I don’t pay PAYE and Pension and other compliance?",
    answer:
      "You have absolute control over what you choose to pay and whom you pay. Eazipay does not share customer data with anyone, and customers may choose to delay certain payments.",
  },
  {
    question: "How fast will my employees receive their salary after payroll is ran?",
    answer: "Employees receive their salaries immediately after you run payroll; Usually within 5 minutes.",
  },
  {
    question: "How is using Eazipay better than using a bank?",
    answer:
      "There are many reasons, but these are the 3 simple ones.<br>i) Avoid errors: Payroll automation ensures extreme accuracy to the finest detail.<br>ii) More Products: You have access to more products for free on Eazipay. Payslips, Automated Payroll, Hiring, Assessments, HR Stores, Loans and Employee loans, and Salary Advance were built with you in mind.<br>iii) No stress: No need to go to the bank again to pay taxes etc. All is done easily on Eazipay.",
  },
  {
    question: "How is using Eazipay better than other payroll software?",
    answer:
      "i) Eazipay is the only payroll solution that really does your compliance end-to-end. If you do not know how to get started with your payroll or compliance, don’t worry. Let us know, and we will assist you with your payroll budget.<br>ii) Over 95% of our customers attest that Eazipay is very easy to use and it takes away their stress.<br>iii) More Products: You have access to more products for free on Eazipay. Hiring, Assessments, HR Stores, Loans and Employee loans, and Salary Advance were built with you in mind.",
  },
  {
    question: "Why should I use Eazipay?",
    answer:
      "i) As a Founder and CEO: You need not worry about Payroll frauds again. Eazipay helps you remit your compliance accurately and support you to pay the right employees on time, with payroll loans.<br>ii) As an Accountant: Keep your payroll records forever in one place at no extra cost, link to your favorite accounting software (coming soon), register and pay PAYE, pension, etc from one place.<br>iii) As a HR Manager: Recruit better and faster with the Eazihires ATS and the AI assessment software. Manage your payroll, bonuses and deductions, prorating salaries, payslips in one place. Order and set up laptops and other IT on Eazipay.",
  },
]);
visibleData.value.push(...faqs.value.slice(0, 5));

function seeMore(sz) {
  if (sz) {
    visibleData.value = [];
    visibleData.value.push(...faqs.value.slice(0, 5));
  } else {
    visibleData.value = [];
    visibleData.value = [...faqs.value];
  }
}

const toggleAccordion = (index) => {
  expanded.value[index] = !expanded.value[index];
};

onMounted(() => {
  faqs.value.forEach((fa, i) => {
    expanded.value[i + 1] = false;
  });
});
</script>

<style lang="scss" scoped>
// all the properties here should be added into tailwind.config.js
.dropdown_menu-6 {
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}
.backgrd {
  background-color: #292a29;
}
.title {
  color: #ffffff;
}
.pad {
  padding: 24px;
  border-top-right-radius: 32px;
  border-top-left-radius: 32px;
}

.full-pad {
  padding: 24px;
  border-radius: 32px;
}

// if you need after/before pseudo elements please use https://github.com/croutonn/tailwindcss-pseudo-elements
.title::after,
.title::before {
  //   content: "";
  position: absolute;
  right: 1.25em;
  top: 1.25em;
  width: 2px;
  height: 0.75em;
  background-color: #7a7572;
  transition: all 0.2s;
}

.title::after {
  transform: rotate(90deg);
}

.content {
  max-height: 0;
  transition: max-height 0.2s;
}

.toggle:checked {
  & + .title + .content {
    max-height: 500px;
  }
  & + .title::before {
    transform: rotate(90deg) !important;
  }
}
</style>
