<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="#515251" />
    <path
      d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM5.58736 16C5.58736 21.7507 10.2493 26.4126 16 26.4126C21.7507 26.4126 26.4126 21.7507 26.4126 16C26.4126 10.2493 21.7507 5.58736 16 5.58736C10.2493 5.58736 5.58736 10.2493 5.58736 16Z"
      fill="#F2F1F1"
    />
    <path
      d="M16 4C9.3736 4 4 9.3736 4 16C4 22.6264 9.3736 28 16 28C22.6264 28 28 22.6264 28 16C28 9.3736 22.6264 4 16 4ZM12.5129 22.1406H9.59039V13.3481H12.5129V22.1406ZM11.0518 12.1475H11.0327C10.052 12.1475 9.41772 11.4724 9.41772 10.6286C9.41772 9.76581 10.0714 9.10938 11.0712 9.10938C12.0709 9.10938 12.6862 9.76581 12.7052 10.6286C12.7052 11.4724 12.0709 12.1475 11.0518 12.1475ZM23.051 22.1406H20.1288V17.4368C20.1288 16.2547 19.7057 15.4485 18.6483 15.4485C17.8409 15.4485 17.3601 15.9923 17.1488 16.5173C17.0715 16.7051 17.0527 16.9677 17.0527 17.2305V22.1406H14.1303C14.1303 22.1406 14.1686 14.173 14.1303 13.3481H17.0527V14.593C17.441 13.9939 18.1359 13.1417 19.6865 13.1417C21.6093 13.1417 23.051 14.3984 23.051 17.099V22.1406Z"
      fill="#F2F1F1"
    />
  </svg>
</template>
