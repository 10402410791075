<template>
  <div class="relative">
    <span class="text-dark-700 block py-1 px-2 cursor-pointer" @click="showNotification = !showNotification">
      <i class="far fa-bell text-xl"></i>
    </span>
    <div
      v-show="showNotification"
      style="width: 100vw; max-width: 528px"
      class="flex z-10 flex-col max-h-screen md:max-h-96 bg-white absolute overflow-y-auto border-t border-dark-100 -mt-1 h-auto -right-52 md:-right-52 top-14 py-2 shadow-lg text-sm"
    >
      <div class="pt-4 px-6 shadow-md">
        <div class="w-full flex items-center justify-between font-medium pb-2">
          <h4 class="text-lg sm:text-2xl">Notifications</h4>
          <!-- <span class="capitalize text-xs sm:text-sm cursor-pointer"
            >Mark all as read</span
          > -->
        </div>
        <!-- <div class="w-full flex gap-4 md:gap-6 mt-2">
          <div
            v-for="tab in tabs"
            :key="tab.name"
            @click="activeTab = tab.name"
            :class="
              activeTab === tab.name
                ? 'border-info border-b-2  text-dark-800 font-bold '
                : 'text-dark-600 font-medium'
            "
            class="cursor-pointer p-2 flex capitalize items-center gap-2 text-sm sm:text-base"
          >
            {{ tab.name }}
            <div
              :class="
                activeTab === tab.name
                  ? 'bg-primary text-white'
                  : 'text-dark-600 bg-dark-100'
              "
              class="rounded font-medium py-1 px-2 sm:ml-1 text-sm sm:text-base"
            >
              {{ padNumber(tab.nofications) }}
            </div>
          </div>
        </div> -->
      </div>
      <div v-if="notifications && notifications.length" class="flex flex-col py-4 text-base">
        <div
          v-for="item in notifications"
          :key="item.id"
          :class="item.status == 'unread' ? 'bg-background-200' : ''"
          class="p-4 w-full flex items-start gap-4 border-b border-dark-200 last:border-b-0"
        >
          <div class="inline-block items-center gap-2 relative rounded-full">
            <div
              v-if="item.creator && item.creator.pfp"
              class="border w-9 sm:w-10 h-9 sm:h-10 rounded-full overflow-hidden"
            >
              <img class="h-full w-full object-cover" :src="item.creator.pfp" :alt="item.name" />
            </div>
            <div
              v-else
              class="bg-primary p-2 w-9 sm:h-10 h-9 sm:w-10 text-sm sm:text-base flex items-center justify-center text-white font-medium rounded-full"
            >
              {{ getAvatar(item.name) }}
            </div>

            <div
              class="bg-success w-3 sm:w-3 h-3 sm:h-3 border-2 border-white absolute -right-1 top-0 rounded-full"
            ></div>
          </div>

          <div class="flex flex-col w-full overflow-hidden items-start text-dark-700">
            <div class="text-base">
              <span v-if="item.creator" class="text-dark-900 font-bold whitespace-nowrap">{{
                `${item.creator.firstName} ${item.creator.lastName}`
              }}</span>
              <span v-else class="text-dark-900 font-bold whitespace-nowrap">{{
                `${adminData && adminData.firstName} ${adminData && adminData.lastName}`
              }}</span>

              <span class="ml-2 break-words">
                {{ item.activity }}
              </span>
            </div>

            <div class="text-xs sm:text-sm">
              <span>{{ item.time }}</span>
              <i class="fas fa-circle text-xs mx-2"></i>
              <span> {{ item.type }}</span>
            </div>

            <div v-if="item.action" class="flex items-center gap-4 mt-2">
              <easiButton color="primary" size="small">Accept</easiButton>
              <easiButton color="primary" size="small" variant="outlined">Decline</easiButton>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="text-center py-6 text-sm md:text-lg">Nothing Here</div>
    </div>
  </div>

  <div
    @click="(showNotification = false), (selected = '')"
    :class="!showNotification ? 'pointer-events-none' : ''"
    class="bg-transparent fixed inset-0"
  ></div>
</template>
<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { storeToRefs, mapWritableState } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { useRoute, useRouter } from "vue-router";
import { helperFunctions } from "@/composable/helperFunctions";

const store = useDataStore();
const { relativeTime, formatAmount, formatDateString } = helperFunctions;
const { companyView, checkLoading } = storeToRefs(store);
const { query } = store;
const router = useRouter();

const showNotification = ref(false);

const loading = ref(false);
const activeTab = ref("all");
const tabs = reactive([
  {
    name: "all",
    nofications: 28,
  },
  {
    name: "unread",
    nofications: 7,
  },
  {
    name: "read",
    nofications: 21,
  },
]);

// const notifications = ref([
//   {
//     employee: {
//       profile: {
//         firstName: "Abiba",
//         lastName: "Isaac",
//         picture: null,
//       },
//     },
//     id: "adfafww",
//     status: "unread",
//     date: new Date().toLocaleDateString(),
//     time: "2H agao",
//     title: "New enrollment",
//     description: "Added 2 new employees 😊",
//     action: null,
//   },
//   {
//     employee: {
//       profile: {
//         firstName: "Tunde",
//         lastName: "Kelani",
//         picture: null,
//       },
//     },
//     id: "adfadaww",
//     status: "unread",
//     date: new Date().toLocaleDateString(),
//     time: "4H agao",
//     title: "Sales and inventory plan",
//     description: "Sent an invoice to Mrs Alagbe 😊",
//     action: null,
//   },
//   {
//     employee: {
//       profile: {
//         firstName: "John",
//         lastName: "Badamusi",
//         picture: null,
//       },
//     },
//     id: "adfa242w",
//     status: "read",
//     date: new Date().toLocaleDateString(),
//     time: "5H agao",
//     title: "Employee management plan",
//     description: "is requesting to upgrade plan",
//     action: true,
//   },
// ]);

const padNumber = (num) => {
  return num && num.toString().padStart(2, "0");
};

const adminData = computed(() => store.getCompanyAdmin && store.getCompanyAdmin.companyAdmin);
const recentActivities = computed(() => store.getRecentActivities.data);

const notifications = computed(() => {
  let arr = [];
  if (recentActivities.value) {
    const obj = {
      Bonus: recentActivities.value.bonusActivities,
      "Employee Management": recentActivities.value.employeeActivities,
      Payroll: recentActivities.value.payrollActivities,
      Wallet: recentActivities.value.walletActivities,
      Deduction: recentActivities.value.deductionActivities,
    };

    Object.keys(obj).map((key) => {
      ////// Add Type
      obj &&
        obj[key] !== undefined &&
        obj[key] !== null &&
        Array.isArray(obj[key]) &&
        obj[key].forEach((el) => {
          const d = new Date(el.createdAt);
          el.year = d.getFullYear();
          el.date = d.getDate();
          el.month = d.getMonth();
          el.day = d.getDay();
          el.type = key;
          el.time = relativeTime(el.createdAt);
        });

      arr = [...arr, ...(obj && obj[key] && Array.isArray(obj[key]) ? obj[key] : [])];
    });
  }

  ////// sort Data //////
  arr = arr.sort((a, b) => b.day - a.day);
  arr = arr.sort((a, b) => b.date - a.date);
  arr = arr.sort((a, b) => b.month - a.month);
  arr = arr.sort((a, b) => b.year - a.year);

  return arr;
});

const getAvatar = (emp) => {
  let name = "";

  if (emp) {
    const initialOne = emp.split(" ")[0] ? String(emp.split(" ")[0].charAt(0)) : "";
    const initialTwo = emp.split(" ")[1] ? String(emp.split(" ")[1].charAt(0)) : "";

    emp ? (name = initialOne + "" + initialTwo) : "N/A";
  }
  return name;
};

onMounted(() => {
  queryActivities();
});

const queryActivities = async () => {
  try {
    loading.value = true;
    await query({
      endpoint: "ListActivities",
      data: {},
      service: "SETTINGS",
      storeKey: "activities",
    });
    loading.value = false;

    // console.log(recentActivities.value);
    // console.log("Notification", notifications.value);
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};
</script>
