<template>
  <Carousel
    :value="banners"
    circular
    :autoplayInterval="5000"
    :numVisible="2"
    :numScroll="2"
    :responsiveOptions="responsiveOptions"
    :showNavigators="!smallerThanLg"
  >
    <template #item="slotProps">
      <div @click="slotProps.data.action" :class="[slotProps.data.action ? 'cursor-pointer' : '']" class="mx-3">
        <img class="w-full object-cover" :src="slotProps.data.banner" alt="" />
      </div>
    </template>
  </Carousel>
</template>

<script setup>
import { useDataStore } from "@/stores/data.js";

import banner1 from "@/assets/img/banner-1.png";
import banner2 from "@/assets/img/banner-2.png";
import banner3 from "@/assets/img/banner-3.png";
import banner4 from "@/assets/img/banner-4.png";
import banner5 from "@/assets/img/banner-5.png";
import banner6 from "@/assets/img/banner-6.png";
import banner7 from "@/assets/img/banner-7.png";
import banner8 from "@/assets/img/banner-8.png";
import banner9 from "@/assets/img/banner-9.png";

import { computed, ref } from "vue";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { useRouter } from "vue-router";

const store = useDataStore();

const router = useRouter();

const breakpoints = useBreakpoints(breakpointsTailwind);
const smallerThanLg = breakpoints.smaller("sm");
const companyDetails = computed(() => store.getCompanyDetails);

const banners = computed(() => {
  const arr = [
    {
      banner: banner1,
      action: () => {
        router.push("/settings/profile");
      },
      show: companyDetails.value && companyDetails.value.tier && companyDetails.value.tier === "2" ? true : false,
    },
    {
      banner: banner2,
      action: null,
    },
    {
      banner: banner3,
      action: () => {
        router.push("/settings/profile");
      },
      show: companyDetails.value && companyDetails.value.tier && companyDetails.value.tier === "1" ? true : false,
    },
    {
      banner: banner4,
      action: () => {
        router.push("/loan");
      },
      show: true,
    },
    {
      banner: banner5,
      action: () => {
        router.push("/admin/tax-filing");
      },
      show: true,
    },
    {
      banner: banner6,
      action: () => {
        router.push("/settings/profile");
      },
      show: companyDetails.value && companyDetails.value.tier && companyDetails.value.tier === "3" ? true : false,
    },
    {
      banner: banner7,
      action: null,
      show: true,
    },
    {
      banner: banner8,
      action: () => {
        router.push("/settings/profile");
      },
      show: companyDetails.value && companyDetails.value.tier && companyDetails.value.tier === "0" ? true : false,
    },
    {
      banner: banner9,
      action: null,
      show: true,
    },
  ];
  const data = arr.filter((banner) => banner.show === true);
  return data;
});

const options = computed(() => {
  return [
    {
      title: "Get your Eazipay",
      show: companyDetails.value && companyDetails.value.tier && companyDetails.value.tier === "0" ? true : false,
      text: "Wallet details",
      action: () => {
        router.push("/settings/profile");
      },
      image: tierZero,

      color: `background: linear-gradient(180deg, #11453B 0%, #2AAB92 100%);`,
      titleStyle: "color: #FFFFFF;",
      textStyle: "color: #D9EBCD; font-weight: 900",
      btnStyle:
        "background: #D9EBCD; box-shadow: 0px 3.6872172355651855px 7.374434471130371px 0px #90A284 inset; color: #11453B",
    },
    {
      title: "Increase your",
      show: companyDetails.value && companyDetails.value.tier && companyDetails.value.tier === "1" ? true : false,
      text: "Transfer Limit",
      action: () => {
        router.push("/settings/profile");
      },
      image: tierOne,
      color: `background: linear-gradient(180deg, #EA4E4B 0%, #F4A6A5 100%);`,
      titleStyle: "color: #FFFFFF;",
      textStyle: textsvg,
      btnStyle:
        "background: #EA4E4B; box-shadow: 0px 2.9968349933624268px 5.9936699867248535px 0px #D62724 inset;color:#ffffff",
    },
    {
      title: "Increase your",
      show: companyDetails.value && companyDetails.value.tier && companyDetails.value.tier === "2" ? true : false,
      text: "Transfer Limit to",
      amount: 5000000,
      banner: tierBanner,
      action: () => {
        router.push("/settings/profile");
      },
      image: tierTwo,
      color: `background: linear-gradient(270deg, rgba(247, 250, 249, 0) 14.95%, #F6F9F8 88.23%);`,
      titleStyle: "color: #11453B;",
      textStyle: "color: #11453B;font-weight: 900",
      btnStyle:
        "background: #11453B; box-shadow: 0px 3.0055980682373047px 6.011196136474609px 0px #063A30 inset; color: #ffffff",
    },
    {
      title: "Increase your",
      show: companyDetails.value && companyDetails.value.tier && companyDetails.value.tier === "3" ? true : false,
      text: "Transfer Limit to",
      amount: 10000000,
      banner: tierBannerOne,
      action: () => {
        router.push("/settings/profile");
      },
      image: tierThree,
      color: `background: linear-gradient(268.86deg, rgba(151, 155, 164, 0) -7.69%, #999DA5 29.3%);`,
      titleStyle: "color: #ffffff;",
      textStyle: "color: #ffffff;font-weight: 900",
      btnStyle: "background: #ffffff; color: #11453B",
    },
    //external
    {
      title: "File your annual taxes with ease.",
      action: () => {
        router.push("/admin/tax-filing");
      },
      image: taxImg,
      color: `background: linear-gradient(90deg, #11453B 0%, rgba(17, 69, 59, 0.30) 100%);`,
      show: true,
      titleStyle: "color: #ffffff;",
      textStyle: "color: #ffffff;font-weight: 900",
      btnStyle: "background: #ffffff; color: #11453B",
      btnText: "Get started now",
    },
    {
      title: "Generate secure API keys for PAYE and Pension",
      action: () => {
        router.push("/developers/dashboard/overview");
      },
      image: apiImg,
      color: `background: linear-gradient(180deg, #EA4E4B 0%, #F5D5D2 109.56%);`,
      show: false,
      titleStyle: "color: #ffffff;",
      textStyle: "color: #ffffff;font-weight: 900",
      btnStyle: "background: #ffffff; color: #11453B",
      btnText: "Get started now",
    },
    //external
  ];
});

const responsiveOptions = ref([
  {
    breakpoint: "1400px",
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: "1199px",
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: "767px",
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: "575px",
    numVisible: 1,
    numScroll: 1,
  },
]);
</script>

<style></style>
