<template>
  <main class="flex md:flex-row flex-col h-screen overflow-auto relative">
    <div
      style="width: 35%"
      class="left overflow-hidden hidden md:flex flex-col p-10 justify-start items-start text-white relative"
    >
      <div class="relative mb-16">
        <div class="grid grid-cols-1 gap-4">
          <img
            v-show="!isFromKyc"
            class="cursor-pointer"
            src="../../assets/img/eazi-logo.svg"
          />
          <img
            v-show="!isFromKyc"
            class="w-9 cursor-pointer"
            src="../../assets/img/profile-add.svg"
          />
          <button
            v-show="isFromKyc"
            @click="$router.go(-1)"
            style="
              color: white;
              background-color: #29584f;
              border-radius: 16px;
              padding: 6px 12px;
              gap: 8px;
              width: 89px;
            "
            class="flex items-center gap-2 focus:outline-none"
          >
            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.558058 1.05806C0.802136 0.813981 1.19786 0.813981 1.44194 1.05806L6 5.61612L10.5581 1.05806C10.8021 0.813981 11.1979 0.813981 11.4419 1.05806C11.686 1.30214 11.686 1.69786 11.4419 1.94194L6.88388 6.5L11.4419 11.0581C11.686 11.3021 11.686 11.6979 11.4419 11.9419C11.1979 12.186 10.8021 12.186 10.5581 11.9419L6 7.38388L1.44194 11.9419C1.19786 12.186 0.802136 12.186 0.558058 11.9419C0.313981 11.6979 0.313981 11.3021 0.558058 11.0581L5.11612 6.5L0.558058 1.94194C0.313981 1.69786 0.313981 1.30214 0.558058 1.05806Z"
                fill="#E7ECEB"
              />
            </svg>
            Close
          </button>
          <h1 v-show="isFromKyc" class="header-text">Complete your KYC</h1>
          <h1 v-show="step === 3 && !isFromKyc" class="header-text">
            You’re just a few steps away <br />
            from the Eazi life.
          </h1>

          <h1 v-show="step !== 3 && !isFromKyc" class="header-text">
            Start Your Journey to an <br />
            Easier Life
          </h1>

          <p v-show="step === 1 && !isFromKyc" class="sub-text">
            Take the first step towards a streamlined business experience with
            Eazipay
          </p>

          <div
            v-show="(step === 1 || step === 2) && !isFromKyc"
            class="mt-3 grid grid-cols-1 gap-3"
          >
            <h3 class="sub-header">Manage Payroll with Ease</h3>
            <span class="sec-sub">
              Automate and manage payroll with just a few clicks
            </span>

            <img
              class="cursor-pointer w-10/12"
              src="../../assets/img/img-stack.png"
            />
          </div>

          <div
            v-show="step !== 4 && !isFromKyc"
            class="mt-3 grid grid-cols-1 gap-2"
          >
            <img class="cursor-pointer w-10/12" :src="imgList[step]" />
          </div>

          <div v-show="isFromKyc" class="mt-3 grid grid-cols-1 gap-2">
            <img class="cursor-pointer w-10/12" :src="kycImgList[step]" />
          </div>

          <div
            v-show="step === 4 && !isFromKyc"
            class="mt-3 grid grid-cols-1 gap-2"
          >
            <img
              v-if="regType === 'PERSONAL'"
              class="cursor-pointer w-10/12"
              src="../../assets/img/steps-2.svg"
            />
            <img
              v-if="regType === 'COMPANY'"
              class="cursor-pointer w-10/12"
              src="../../assets/img/steps-3.svg"
            />
          </div>
        </div>
      </div>

      <div v-show="step >= 4" class="absolute left-0 right-0 w-full bottom-0">
        <img
          v-if="step === 4 && regType === 'PERSONAL'"
          src="../../assets/img/wave-pattern.svg"
          alt=""
        />
        <img
          v-if="step >= 4 && regType === 'COMPANY'"
          src="../../assets/img/wave-pattern-small.svg"
          alt=""
        />
      </div>
    </div>

    <div class="z-50 md:hidden absolute top-0 right-0 left-0">
      <img class="top-0 w-full h-full mb-4" :src="mobileImgList[step]" alt="" />
    </div>

    <div class="mt-36 md:mt-0 w-full right">
      <slot></slot>
    </div>
  </main>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import steps from "../../assets/img/steps.svg";
import steps4 from "../../assets/img/steps-4.svg";
import steps5 from "../../assets/img/steps-5.svg";
import steps6 from "../../assets/img/steps-6.svg";

import kycStep4Personal from "../../assets/img/kyc-step-1-personal.svg";
import kycStep4 from "../../assets/img/kyc-step-1.svg";
import kycStep5 from "../../assets/img/kyc-step-2.svg";
import kycStep6 from "../../assets/img/kyc-step-3.svg";
import kycStep7 from "../../assets/img/kyc-step-4.svg";

import mobileStep from "../../assets/img/eazi-gradient.svg";
import mobileStep2 from "../../assets/img/eazi-gradient-2.svg";
import mobileStep3 from "../../assets/img/eazi-gradient-3.svg";
import mobileStepSetup from "../../assets/img/eazi-gradient-setup.svg";

const route = useRoute();
const isFromKyc = computed(() => route.query.forKYC === "true");

const imgList = { 3: steps, 5: steps4, 6: steps5, 7: steps6, 8: steps6 };

const mobileImgList = [
  mobileStep,
  mobileStep2,
  mobileStep3,
  mobileStep3,
  mobileStepSetup,
  mobileStepSetup,
  mobileStepSetup,
  mobileStepSetup,
  mobileStepSetup,
  mobileStepSetup,
  mobileStepSetup,
];
const props = defineProps({
  step: {
    type: Number,
    default: 0,
  },
  regType: {
    type: String,
    default: "PERSONAL",
  },
});

const watchRegType = computed(() => props.regType);
const kycImgList = {
  3: kycStep4,
  4:
    watchRegType.value && watchRegType.value === "PERSONAL"
      ? kycStep4Personal
      : kycStep4,
  // 4: kycStep4,
  5: kycStep5,
  6: kycStep6,
  7: kycStep7,
  8: kycStep7,
};
const carousel = ref(null);
const translateValue = ref(650);
const currentIndex = ref(0);
const slides = ref([
  {
    header: "Did you know?",
    content:
      "You can register and remit a wide range of compliances (PAYE, Pension, HMO, NSITF, ITF, NHF, WHT) across the 36 states in Nigeria on Eazipay?",
  },
  {
    header: "Did you know?",
    content:
      "Eazipay provides you with payroll cover and business loans of up to 10 Million Naira",
  },
  {
    header: "Did you know?",
    content:
      "Your employee data and company information is 100% safe and secure on Eazipay. No leaks, no breaches.",
  },
]);

const slideRefs = ref([]); // Array to store slide refs

// Define default slide width
let slideWidth = 600;

onMounted(() => {
  // Start auto-scrolling
  startAutoScroll();
});

const startAutoScroll = () => {
  setInterval(() => {
    currentIndex.value = (currentIndex.value + 1) % slides.value.length;
    if (currentIndex.value === 0) {
      translateValue.value = 650;
    } else if (currentIndex.value === 1) {
      translateValue.value = 10;
    } else if (currentIndex.value === 2) {
      translateValue.value = -630;
    }
  }, 2500); // Auto-scroll every 1 second
};
</script>

<style scoped>
.header-text {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.sub-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #b9b9b9;
}

.sub-header {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #e7eceb;
}

.sec-sub {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #f3f6f5;
}
.left {
  /* background: url("../../assets/img/newBackground.svg") no-repeat center center; */

  background: linear-gradient(to bottom, #11453b 0%, #1e5e51 50%, #75a388 100%);

  background-position: center center;
  background-size: cover;
  /* height: 100vh; */
}
.backgroundBanner {
  background: url("../../assets/img/backgroundBanner.svg") no-repeat center
    center;
  background-position: center center;
  background-size: cover;
  /* height: 100vh; */
}

.right {
  /* height: 100vh; */
  overflow: auto;
  background: white;
}
@media (min-width: 1024px) {
  .right {
    /* background: url("../../assets/img/signupBackground.png") no-repeat center; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.imgSlider {
  height: 446px;
  width: 485px;
}
.contentWidth {
  max-width: 450px;
}

.animation-slides {
  animation: slide 2s linear infinite alternate;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100%));
  }
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease;
}
</style>
