const pensionProvidersWithCode = {
  "Stanbic IBTC Pension Managers Limited": "021",
  "Premium Pension Limited": "022",
  "Leadway Pensure Limited": "023",
  "Sigma Pensions Limited": "024",
  "Pension Alliance Limited": "025",
  "ARM Pension Managers Limited": "026",
  "Trustfund Pensions PLC": "028",
  "First Guarantee Pension Limited": "029",
  "Legacy Pension Managers Limited": "030",
  "NLPC Pension Fund Administrators Limited": "031",
  "CrusaderSterling Pensions Limited": "032",
  "AIICO Pension Managers Limited": "033",
  "OAK Pensions Limited": "034",
  "AXA Mansard Pension Limited": "035",
  "IEI-Anchor Pension Managers Limited": "036",
  "APT Pension Fund Managers Limited": "037",
  "Investment One Pension Managers Limited": "040",
  "Future Unity Glanvils Pensions Limited": "042",
  "Fidelity Pension Managers": "043",
  "Radix Pension Managers Limited": "046",
  "NPF Pensions Limited": "047",
};

export default pensionProvidersWithCode;
