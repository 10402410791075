<template>
  <main>
    <div :class="[borderClass]" class="w-full relative flex items-center">
      <div
        v-if="
          isFocused ||
          label ||
          $attrs.placeholder ||
          modelValue ||
          String(modelValue).length ||
          formatAmount
        "
        style="transition: top, font-size, 0.1s ease-in-out"
        class="absolute mt-3 z-10 pointer-events-none"
        :class="`${labelStyle}`"
      >
        <div
          v-show="modelValue || isFocused || formatAmount"
          class="w-full h-full text-sm relative bg-white"
        >
          {{ label && !$attrs.placeholder ? label : $attrs.placeholder }}
          <span
            v-if="isFocused || modelValue || formatAmount"
            style="width: 110%; z-index: -2; margin-top: -4px"
            class="borderLine absolute top-1/2 -left-1 border-4 border-white"
          ></span>
        </div>
      </div>
    </div>

    <textarea
      :name="name"
      :id="id"
      :cols="cols"
      :rows="rows"
      v-bind="$attrs"
      :class="[errorClass, emp ? 'py-1' : 'py-3 p-16']"
      class="bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border px-4 block appearance-none rounded-2xl m-0"
      :placeholder="!isFocused ? $attrs.placeholder : ''"
      :value="formatAmount"
      @focus="isFocused = true"
      @blur="isFocused = false"
      @input="updateField($event)"
    ></textarea>

    <div v-if="error" class="text-xs font-medium text-error mt-2 text-left">
      {{ error }}
    </div>
  </main>
</template>
<script>
export default {
  setup() {
    function containsMaliciousInput(input) {
      // Regular expressions to match potentially harmful characters or patterns
      var patterns = [
        /<script/i,
        /<\/script/i,
        /[<>]/,
        /on\w+\s*=/i,
        /<[^>]*>/,
        /<\/[^>]*>/,
        /<\/[^>]+>/,
        // Matches any closing tag (e.g., </div>, </img>)
      ];

      // Check if any of the patterns match the input
      for (var i = 0; i < patterns.length; i++) {
        if (patterns[i].test(input)) {
          return true; // Found a match, input is potentially malicious
        }
      }

      return false; // No potentially harmful patterns found
    }
    return { containsMaliciousInput };
  },
  inheritAttrs: false,
  data() {
    return {
      isFocused: false,
      holder: "test",
    };
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    forSelect: {
      type: Boolean,
      default: false,
    },

    error: {
      type: [String, Boolean],
      default: null,
    },
    format: {
      type: Boolean,
      default: false,
    },
    emp: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    cols: {
      type: String,
      default: "30",
    },
    rows: {
      type: String,
      default: "4",
    },
  },

  computed: {
    formatAmount() {
      if (this.containsMaliciousInput(this.modelValue)) {
        return "";
      }
      if (this.format) {
        let val = this.modelValue;
        val = this.convertFloat(val);
        val = val.toLocaleString();

        if (val === "NaN") {
          return "";
        } else {
          return `₦${val}`;
        }
      } else {
        return this.modelValue;
      }
    },
    errorClass() {
      if (this.error) {
        return "focus:border-error border-error";
      } else {
        return "focus:border-primary focus:ring-primary border-dark-300";
      }
    },
    borderClass() {
      let style = "";
      if (this.error) {
        style = "border-error";
      }
      if (this.forSelect) {
        if (this.isFocused) {
          style = "border-primary py-0";
        } else {
          style = "border-dark-300 py-0";
        }
      } else {
        if (this.isFocused) {
          style = "border-primary py-3";
        } else {
          style = "border-dark-300 py-3";
        }
      }

      return style;
    },
    labelStyle() {
      let style = [];

      if (this.isFocused) {
        style = " floatingLabel text-sm ";
        style = this.error ? `${style} text-error` : `${style} text-primary`;
        if (this.$slots.append) {
          style = `${style} left-6`;
        }
      } else {
        if (String(this.modelValue).trim() === "") {
          style =
            " first top-1/2 left-7 transform -translate-y-1/2 text-dark-300";
        } else {
          style = this.error
            ? "other floatingLabel text-sm text-error"
            : " other floatingLabel text-sm text-primary";
        }
        if (this.$slots.append) {
          style = `${style} left-6`;
        }
      }

      return style;
    },
  },

  methods: {
    convertFloat(num) {
      if (num && (typeof num === "number" || typeof num === "string")) {
        return parseFloat(num);
      }
      return 0;
    },
    updateField(e) {
      let amount = String(e.target.value);
      // console.log(this.holder.concat("", e.target.value));

      if (this.format) {
        amount = this.removeAlphabet(amount);
        e.target.value = amount;

        const firstChar = amount && amount.length ? amount.charAt(0) : "0";
        if (firstChar === "₦") {
          amount = amount.substring(1);
        }

        if (amount !== null) {
          amount = amount.split(",").join("");
          amount = this.convertFloat(amount);
        }
      }

      this.$emit("update:modelValue", amount);
    },

    removeAlphabet(arg) {
      if (arg) {
        arg = arg.toString();
        // const regex = /[a-zA-Z]/g;
        let regex = /[^0-9.]/g; // Include decimal point in the regex
        arg = arg.replace(regex, "");
        this.$emit("update:modelValue", this.convertFloat(arg));
        return arg;
      }
    },
  },
};
</script>

<style scoped>
.m-0 {
  margin: 0px !important;
}
</style>
