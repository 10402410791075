const pensionProviders = [
  "Stanbic IBTC Pension Managers Limited",
  "Premium Pension Limited",
  "Leadway Pensure Limited",
  "Sigma Pensions Limited",
  "Pension Alliance Limited",
  "ARM Pension Managers Limited",
  "Trustfund Pensions PLC",
  "First Guarantee Pension Limited",
  "Legacy Pension Managers Limited",
  "NLPC Pension Fund Administrators Limited",
  "CrusaderSterling Pensions Limited",
  "AIICO Pension Managers Limited",
  "OAK Pensions Limited",
  "AXA Mansard Pension Limited",
  "IEI-Anchor Pension Managers Limited",
  "APT Pension Fund Managers Limited",
  "Investment One Pension Managers Limited",
  "Future Unity Glanvils Pensions Limited",
  "Fidelity Pension Managers",
  "Radix Pension Managers Limited",
  "NPF Pensions Limited",
];

export default pensionProviders;
