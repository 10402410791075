<template>
  <div class="md:full w-full py-5 mx-auto text-left">
    <div class="mb-6">
      <h2
        class="px-3 md:px-12 lg:px-8 xl:px-10 text-left text-headerText font-medium text-lg md:text-2xl"
      >
        Edit Deduction
      </h2>
      <hr class="my-4 border-0.5 border-dividerColor text-dividerColor" />
    </div>
    <form
      @submit.prevent="UpdateDeductions"
      autocomplete="on"
      class="flex flex-col"
    >
      <div class="flex flex-col px-6 lg:px-8 xl:px-10">
        <!-- <label for="deduction" class="text-dark-800 text-sm font-medium"
          >Select Deduction</label
        > -->
        <ToggleButtons
          :options="['Auto calculate', 'Input amount']"
          :deductions="true"
          class="mb-3"
          :error="dedTypeErr"
          @update="
            ($event) => {
              autoCalculate = $event;
              dedTypeErr = '';
            }
          "
          :bool="autoCalculate"
        />
        <easiDeduction
          @update="checkUpdate($event)"
          :value="benefits"
          :grossAmount="grossAmount"
          :options="benefitsOptions"
          :validate="validate"
          :autoCalculate="autoCalculate"
          label="Select Statutory Deduction"
        ></easiDeduction>
      </div>

      <div
        class="w-full mt-7 mb-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <span
          @click="cancelOperation"
          class="w-80 md:w-56 text-center bg-white rounded-full text-primary font-bold border border-primary px-3 text-sm py-3 cursor-pointer"
          >Cancel</span
        >
        <easiButton
          :loading="loading"
          class="w-80 md:w-56 focus:outline-none text-sm py-3 px-4 bg-primary rounded-lg text-white"
        >
          Update
        </easiButton>
      </div>
    </form>
    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Deductions updated successfully</span>
      </template>
    </easiSuccess>
  </div>
</template>

<script setup>
import picIcon from "@/assets/icons/pic.svg";

import { useRoute } from "vue-router";
import { ref, reactive, nextTick, watch, computed } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import benefitsOptions from "@/utils/benefits";
import ToggleButtons from "@/components/global/ToggleButtons.vue";

const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees } = storeToRefs(store);
const toast = useToast();
const { resetObj } = helperFunctions;

const route = useRoute();
const props = defineProps({
  type: {
    type: String,
    default: "edit",
  },
});
const emit = defineEmits(["cancel"]);
function cancelOperation() {
  emit("cancel", false);
}
const validate = ref(true);
const autoCalculate = ref(null);
const dedTypeErr = ref("");

let updateSuccess = ref(false);
const payload = reactive({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});

const tempBen = ref([]);
const benefits = ref([]);
const grossAmount = ref(0);
const loading = ref(false);

const args = reactive({
  staffLevel: {
    benefits: [],
    hmo: {
      provider: "",
      amount: 0,
      duration: "",
      plan: "",
      chargeTo: "EMPLOYEE",
    },
    lifeInsurance: {
      provider: "",
      amount: 0,
      duration: "",
    },
    lifeAssurance: {
      provider: "",
      amount: 0,
      duration: "",
      chargeTo: "EMPLOYEE",
    },
    itf: {
      amount: 0,
    },
    nsitf: {
      amount: 0,
    },
    pension: {
      amount: 0,
      provider: "",
      employerPensionContribution: 0,
      employeePensionContribution: 0,
      providerPin: "",
      usePercentage: false,
      manually_inputted: false,
      employeePensionPin: "",
    },
    paye: {
      amount: 0,
      stateForPaye: "",
      taxId: "",
    },
    nhf: {
      amount: 0,
    },
    gratuity: {
      salaryAmount: 0,
      yearsWorked: 0,
      amount: 0,
    },
    businessExpense: { amount: 0, expenseName: "" },
    wht: {
      amount: 0,
      paymentTypes: [],
      percentage: 0,
    },
  },
});

const employeeId = route.params.id;

function checkUpdate(event) {
  // tempBen.value = benefits.value;
  benefits.value = event.benefits;

  args.staffLevel = Object.assign({}, event);
  // const merged = tempBen.value.concat(bens);
  // benefits.value = merged;
  if (args.staffLevel) {
    for (const key in args.staffLevel) {
      if (
        args.staffLevel[key] &&
        typeof args.staffLevel[key].manually_inputted === "boolean"
      ) {
        autoCalculate.value = !args.staffLevel[key].manually_inputted;
        break;
      }
    }
  }
}

async function queryEmployees() {
  await query({
    endpoint: "ListEmployees",
    payload: payload,
    service: "EMP",
    storeKey: "listEmployees",
  });
}
function getEmployeeById(id) {
  let employee = [];
  if (employeeId) {
    employee = getAllEmployees.value.data.filter((emp) => emp._id === id);
  }
  return employee;
}
function areAllPropertiesDefined(obj) {
  const keys = Object.keys(obj);
  const exemp = ["providerPin", "taxId", "paymentTypes"];
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (!exemp.includes(key)) {
      if (obj[key] === undefined || obj[key] === null || obj[key] === "") {
        return false;
      }
    }
  }
  return true;
}
function processArgs() {
  if (args) {
    let payload = {};
    if (args.staffLevel && args.staffLevel.hmo) {
      args.staffLevel.hmo.amount = Number(args.staffLevel.hmo.amount);
    }
    if (args.staffLevel && args.staffLevel.lifeInsurance) {
      args.staffLevel.lifeInsurance.amount = Number(
        args.staffLevel.lifeInsurance.amount
      );
    }

    if (args.staffLevel && args.staffLevel.lifeAssurance) {
      args.staffLevel.lifeAssurance.amount = Number(
        args.staffLevel.lifeAssurance.amount
      );
    }
    if (args.staffLevel && args.staffLevel.wht) {
      args.staffLevel.wht.amount = Number(args.staffLevel.wht.amount);
      args.staffLevel.wht.percentage = Number(args.staffLevel.wht.percentage);
    }

    if (args.staffLevel && args.staffLevel.itf) {
      args.staffLevel.itf.amount = Number(args.staffLevel.itf.amount);
    }

    if (args.staffLevel && args.staffLevel.nsitf) {
      args.staffLevel.nsitf.amount = Number(args.staffLevel.nsitf.amount);
    }

    if (args.staffLevel && args.staffLevel.pension) {
      args.staffLevel.pension.amount = autoCalculate.value
        ? Number(args.staffLevel.pension.amount)
        : 0;
      args.staffLevel.pension.employerPensionContribution = Number(
        args.staffLevel.pension.employerPensionContribution
      );
      args.staffLevel.pension.employeePensionContribution = Number(
        args.staffLevel.pension.employeePensionContribution
      );

      // args.staffLevel.pension.employerPensionContribution = args.staffLevel
      //   .pension.manually_inputted
      //   ? Number(args.staffLevel.pension.employerPensionContribution)
      //   : 0;
      // args.staffLevel.pension.employeePensionContribution = args.staffLevel
      //   .pension.manually_inputted
      //   ? Number(args.staffLevel.pension.employeePensionContribution)
      //   : 0;
    }

    if (args.staffLevel && args.staffLevel.paye) {
      args.staffLevel.paye.amount = Number(args.staffLevel.paye.amount);
    }

    if (args.staffLevel && args.staffLevel.nhf) {
      args.staffLevel.nhf.amount = Number(args.staffLevel.nhf.amount);
    }
    if (args.staffLevel && args.staffLevel.gratuity) {
      args.staffLevel.gratuity.amount = Number(args.staffLevel.gratuity.amount);
      args.staffLevel.gratuity.salaryAmount = Number(
        args.staffLevel.gratuity.salaryAmount
      );
      args.staffLevel.gratuity.yearsWorked = Number(
        args.staffLevel.gratuity.yearsWorked
      );
    }

    if (args.staffLevel && args.staffLevel.businessExpense) {
      args.staffLevel.businessExpense.amount = Number(
        args.staffLevel.businessExpense.amount
      );
    }

    const arr = [
      // "paye",
      "pension",
      "hmo",
      "lifeInsurance",
      "lifeAssurance",
      // "nsitf",
      // "itf",
      // "nhf",
      "wht",
      "businessExpense",
      "gratuity",
    ];
    for (let item of benefits.value) {
      // if (!benefits.value.includes(item)) {
      //   console.log(item, "N/A");
      //   delete args.staffLevel[item];
      //   return args.staffLevel;
      // } else {
      if (
        args.staffLevel[item] &&
        !areAllPropertiesDefined(args.staffLevel[item])
      ) {
        payload[item] = args.staffLevel[item];
        // console.log(args.staffLevel[item]);
        // toast.error(
        //   "Please fill in the details of other selected deduction before continuing"
        // );
        // throw new Error("Fill in all details");
      } else {
        payload[item] = args.staffLevel[item];
      }
      payload[item].manually_inputted = !autoCalculate.value; //set manually inputted or not
      // }
      // payload[item] = args.staffLevel[item];
    }
    // const removeObj = ["nsitf", "itf", "nhf", "paye", "pension"];
    // const newObj = removeObj.map((ro) => {
    //   if (!benefits.value.includes(ro)) {
    //     delete args.staffLevel[ro];
    //     return args.staffLevel;
    //   }
    // });

    args.staffLevel.benefits = benefits.value.map((item) => {
      if (item === "paye") return "PAYE";
      if (item === "pension") return "Pension";
      if (item === "nhf") return "NHF";
      if (item === "nsitf") return "NSITF";
      if (item === "itf") return "ITF";
      if (item === "wht") return "WHT";
      if (item === "lifeInsurance") return "Group_Life_Insurance";
      if (item === "lifeAssurance") return "Employee_Life_Assurance";
      if (item === "hmo") return "HMO";
      if (item === "businessExpense") return "Business_Expense";
      if (item === "gratuity") return "Gratuity";
    });
    payload["benefits"] = args.staffLevel.benefits;
    return payload;
  }
  return false;
}

async function UpdateDeductions() {
  try {
    loading.value = true;
    delete args.staffLevel.allowanceDetails;
    delete args.staffLevel.grossAmount;
    delete args.staffLevel.withholdingTax;
    delete args.staffLevel.usePercentageForAllowance;
    const payload = processArgs();
    let res = await mutate({
      endpoint: "UpdateDeductions",
      data: {
        staffLevelId,
        // input: args.staffLevel,
        input: payload,
      },
      service: "PAYROLL",
    });
    if (res.success) {
      loading.value = false;
      updateSuccess.value = true;
      await queryEmployees();
      // toast.success("Deductions Updated Successfuly");

      // Object.assign(args, resetObj(args, "args"));
      setTimeout(() => {
        updateSuccess.value = false;
        window.location.reload();
      }, 500);
    }
  } catch (e) {
    console.log(e);
    toast.error(e.message);
    loading.value = false;
  } finally {
    loading.value = false;
  }
}
function populateBenefit() {
  const SL = employees[0].staffLevel;
  const arr = [
    "paye",
    "pension",
    "hmo",
    "lifeInsurance",
    "lifeAssurance",
    "nsitf",
    "itf",
    "nhf",
    "gratuity",
    "businessExpense",
  ];
  arr.map((item) => {
    if (SL[item] !== null) {
      // console.log(args.staffLevel[item]);
      benefits.value.push(item);
    }
  });
}

queryEmployees();
const employees = getEmployeeById(route.params.id);
// console.log(employees[0].staffLevel);
// populateBenefit();
const staffLevelId = employees[0].staffLevel._id;
grossAmount.value = employees[0].staffLevel.grossAmount;
</script>

<style></style>
