<template>
  <main style="z-index: 999" class="relative bg-white">
    <div
      class="bg-white w-full h-screen absolute top-0 left-0 right-0 bottom-0 flex flex-col gap-4 justify-center items-center"
    >
      <div>
        <img class="w-7/12 mx-auto" src="@/assets/img/hurray.png" alt="" />
      </div>

      <h1
        style="
          font-weight: 700;
          font-size: 36px;
          line-height: 48px;
          text-align: center;
          color: #292a29;
        "
      >
        {{
          $route.query.setupComplete ? "Setup Complete!" : "Welcome to Eazipay"
        }}
      </h1>
      <p
        style="
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          text-align: center;
          color: #888888;
        "
      >
        {{
          $route.query.setupComplete
            ? "You’re ready to go!"
            : "You’re all set to begin your journey"
        }}
      </p>

      <p
        style="
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #474747;
        "
        v-if="$route.query.setupComplete"
      >
        Congratulations! Your account setup is complete, and <br />
        you’re all set to start using Eazipay
      </p>

      <p
        style="
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #474747;
        "
        v-else
      >
        Hi {{ user.firstName }}! Now that you’re logged in, let’s <br />
        complete your account setup to unlock all features.
      </p>

      <easiButton
        v-if="$route.query.setupComplete"
        class="mt-4"
        @click="$router.push({ name: 'AdminDashboard' })"
        >Back to Dashboard</easiButton
      >
      <easiButton
        v-else
        class="w-32 mt-4"
        @click="$router.push({ name: 'Register', query: { forSetup: true } })"
        >Let's go</easiButton
      >
    </div>
  </main>
</template>

<script setup>
const user = JSON.parse(window.localStorage.getItem("userDetails"));
</script>

<style></style>
