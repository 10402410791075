<template>
  <div
    v-if="edit"
    class="w-full py-5 text-left bg-white rounded-tr-none md:rounded-xl rounded-tl-none rounded-lg"
    :class="
      multipleEdit
        ? 'md:w-full mx-0 h-full -mt-5'
        : 'md:w-9/12 mx-auto md:mt-16 shadow-md border border-dark-50'
    "
  >
    <div v-if="viewValue === 'benefits'" class="mb-6">
      <div v-if="!multipleEdit" class="flex justify-end">
        <div @click="$router.go(-1)" class="mr-5 mb-2 cursor-pointer">
          <img class="" src="@/assets/icons/close.svg" alt="" />
        </div>
      </div>
      <h2
        class="px-6 sm:px-12 lg:px-8 xl:px-10 text-left text-headerText font-bold md:font-medium text-2xl"
      >
        Edit {{ multipleEdit ? "Group" : "Benefits" }}
      </h2>
      <hr class="my-4 border-0.5 border-dividerColor text-dividerColor" />
    </div>
    <form
      v-if="viewValue === 'benefits'"
      class="flex flex-col"
      @submit.prevent="updateAllowanceDetails"
      autocomplete="on"
    >
      <div class="flex flex-col px-3 md:px-12 lg:px-8 xl:px-10">
        <!-- <label for="grossPay" class="text-dark-800 text-sm font-medium"
          >Gross Pay</label
        > -->
        <easiTextInput
          placeholder="Gross Salary"
          v-model="args.salary.grossAmount"
          type="text"
          :format="true"
          name="grossPay"
          class="mt-1 mb-5"
          required
        ></easiTextInput>

        <!-- <label for="netpay" class="text-inactive text-sm font-medium"
          >Net Pay</label
        > -->
        <easiTextInput
          placeholder="Net Pay"
          v-model="args.salary.netAmount"
          type="text"
          :format="true"
          name="netpay"
          class="mt-1 mb-5 text-inactive"
          readonly
          required
        ></easiTextInput>
      </div>
      <hr v-if="!multipleEdit" class="mb-4 mt-4 text-dividerColor" />

      <div v-if="!multipleEdit" class="flex flex-col items-center gap-4">
        <h3 class="font-bold text-dark-800">Please Select Allowance Type</h3>

        <form class="flex gap-4" action="">
          <div>
            <label class="text-dark-800 font-normal text-sm mr-2" for="amount"
              >Amount</label
            >
            <input
              type="radio"
              name="percentage"
              id="percentage"
              :value="false"
              v-model="args.usePercentageForAllowance"
            />
          </div>

          <div>
            <label
              class="text-dark-800 font-normal text-sm mx-2"
              for="percentage"
              >Percentage of Total Salary</label
            >
            <input
              type="radio"
              name="percentage"
              id="percentage"
              :value="true"
              v-model="args.usePercentageForAllowance"
            />
          </div>
        </form>
      </div>
      <h4
        v-if="!multipleEdit"
        class="text-dark-800 my-5 font-normal text-center"
      >
        Sum of all allowances must be equal to gross pay.
      </h4>

      <h2
        v-if="!multipleEdit"
        class="mt-6 mb-2 px-3 md:px-12 lg:px-8 xl:px-10 text-left text-headerText font-medium text-lg md:text-2xl"
      >
        Salary Breakdown
      </h2>
      <hr v-if="!multipleEdit" class="mb-1 mt-2 text-dividerColor" />

      <div
        v-if="!multipleEdit"
        class="grid grid-cols-2 gap-x-4 gap-y-4 items-center px-3 md:px-12 lg:px-8 xl:px-10 mt-4"
      >
        <div
          class="flex flex-col col-span-2 md:col-span-1 w-full"
          v-for="allowance in fixedAllowances"
          :key="allowance._id"
        >
          <!-- <label
            :for="allowance.name"
            class="capitalize text-dark-800 text-sm font-medium"
          >
            {{ allowance.name }}</label
          > -->
          <easiTextInput
            step="any"
            :placeholder="capitalizeFirstLetter(allowance.name)"
            v-model="allowanceData[allowance._id]"
            :type="args.usePercentageForAllowance ? 'number' : 'text'"
            :format="args.usePercentageForAllowance ? false : true"
            :name="String(allowance._id)"
            class="mt-1 mb-2"
          ></easiTextInput>
          <p
            v-show="
              args.usePercentageForAllowance === true &&
              removeSymbolsFromString(allowanceData[allowance._id]) > 100
            "
            class="text-error text-xs capitalize"
          >
            percentage value can't be more than 100
            <!-- {{ removeSymbolsFromString(allowanceData[allowance._id]) }} -->
          </p>
        </div>
      </div>
      <hr v-if="!multipleEdit" class="mb-4 mt-4 text-dividerColor" />

      <h2
        v-if="!multipleEdit"
        v-show="otherAllowances.length"
        class="mt-2 mb-0 px-3 md:px-12 lg:px-8 xl:px-10 text-left text-headerText font-medium text-lg md:text-2xl"
      >
        Allowances
      </h2>

      <div
        v-if="!multipleEdit"
        class="grid grid-cols-2 gap-x-4 gap-y-4 items-center px-3 md:px-12 lg:px-8 xl:px-10 mt-1"
      >
        <div
          class="flex flex-col col-span-2 md:col-span-1"
          v-for="allowance in otherAllowances"
          :key="allowance._id"
        >
          <!-- <label
            v-if="
              selectedAllowances.includes(allowance._id) ||
              removeSymbolsFromString(allowanceData[allowance._id]) > 0
            "
            :for="allowance.name"
            class="capitalize text-dark-800 text-sm font-medium"
          >
            {{ allowance.name }}</label
          > -->
          <easiTextInput
            step="any"
            :placeholder="capitalizeFirstLetter(allowance.name)"
            v-model="allowanceData[allowance._id]"
            :format="args.usePercentageForAllowance ? false : true"
            :type="args.usePercentageForAllowance ? 'number' : 'text'"
            name="lastName"
            class="mt-0 mb-2"
          ></easiTextInput>
          <p
            v-show="
              args.usePercentageForAllowance === true &&
              removeSymbolsFromString(allowanceData[allowance._id]) > 100
            "
            class="text-error text-xs capitalize"
          >
            percentage value can't be more than 100
            <!-- {{ removeSymbolsFromString(allowanceData[allowance._id]) }} -->
          </p>
        </div>

        <div
          v-show="!multipleEdit"
          class="col-span-2 cursor-pointer justify-center mt-4"
        >
          <span
            @click="addAllowance = true"
            class="text-secondary font-bold text-sm"
            ><i class="fas fa-plus mr-2"></i> Add Allowance</span
          >
        </div>
      </div>

      <hr class="my-6 text-dividerColor" />

      <!--Kept in case bank details is later required here-->
      <div
        v-if="multipleEdit"
        class="w-full my-4 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <span
          @click="cancelMultipleEdit"
          class="cursor-pointer w-80 md:w-56 text-center bg-white rounded-full text-dark-800 border border-primary px-3 text-sm py-3"
          >Cancel</span
        >
        <span
          @click="updateData"
          class="w-80 md:w-56 focus:outline-none text-sm py-3 px-4 bg-primary text-center rounded-full text-white"
        >
          Update
        </span>
      </div>
      <div
        v-if="!multipleEdit"
        class="w-full mt-4 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <span
          @click="cancelOperation"
          class="cursor-pointer w-80 md:w-60 text-center bg-white rounded-full text-dark-800 border border-primary px-3 text-sm py-3"
          >Cancel</span
        >
        <easiButton
          :loading="loading"
          class="w-80 md:w-60 focus:outline-none text-sm py-3 px-4 bg-primary text-center rounded-full text-white"
        >
          Update
        </easiButton>
      </div>
      <div class="max-w-100 pt-5 md:pt-0 md:p-6 bg-white md:-mt-0 -mt-6">
        <div class="flex flex-wrap gap-2 mt-1 mb-3 justify-center items-center">
          <div
            v-for="emp in dataForMultipleEdit"
            :key="emp"
            class="flex items-center flex-nowrap justify-around gap-x-3 rounded-lg px-2 py-0.5 text-center bg-lightSecondary border border-gray text-xs w-auto"
          >
            <span
              @click="updateEmp(emp)"
              class="flex-none w-6 h-6 inline-flex items-center justify-center rounded-full"
              :style="`background-color:${
                initials(`${emp.firstName} ${emp.lastName}`).color
              }`"
            >
              <span class="shrink-0 text-white text-xs font-medium">{{
                initials(`${emp.firstName} ${emp.lastName}`).str
              }}</span></span
            >

            <span class="grow cursor-pointer text-xs">
              {{ `${emp.firstName} ${emp.lastName}` }}</span
            >
            <img
              class="flex-none cursor-pointer"
              @click="updateEmp(emp)"
              src="@/assets/icons/remove.svg"
              alt=""
            />
          </div>
        </div>
        <div></div>
      </div>
    </form>
    <Deduction @cancel="edit = $event" v-if="viewValue === 'deductions'" />
    <BankDetails @cancel="edit = $event" v-if="viewValue === 'bank'" />

    <easiModal v-if="addAllowance === true" @close="addAllowance = false">
      <template v-slot:header>
        <h1 class="capitalize font-semibold text-3xl text-headerText">
          Add Allowance
        </h1>
      </template>

      <form @submit.prevent="createAllowance" class="mb-5 flex flex-col w-full">
        <div class="flex flex-col text-left px-8 w-full">
          <!-- <label
            for="allowanceName"
            class="text-gray text-left text-xs font-medium"
            >Select from existing allowance</label
          > -->
          <!-- <easiMultiSelectInput
            label="Select from existing allowance"
            :options="allowanceOptions"
            :value="selectedAllowances"
            @update="selectedAllowances = $event"
            name="allowanceName"
            class="mt-1 mb-2 capitalize"
          />

          <span
            @click="addNew = !addNew"
            class="cursor-pointer text-primary text-center text-sm font-medium my-3"
            >Add New <i v-show="addNew" class="fas fa-angle-right"></i>
            <i v-show="!addNew" class="fas fa-angle-down"></i>
          </span> -->
          <!-- <label
            for="allowanceName"
            class="text-gray text-left text-xs font-medium"
            >Allowance Name</label
          > -->
          <easiTextInput
            placeholder="Enter Allowance name"
            v-model="allowanceArg.name"
            type="text"
            name="allowanceName"
            class="mt-1 mb-5 px-8 max-w-full"
          ></easiTextInput>
        </div>
        <hr
          class="mt-6 mb-4 border-0.5 border-dividerColor text-dividerColor"
        />
        <div class="px-8 flex mt-4 justify-center mx-auto">
          <easiButton :loading="loading" class="px-20">Submit</easiButton>
        </div>
      </form>
    </easiModal>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Employee benefits updated successfully</span>
      </template>
    </easiSuccess>
  </div>
  <ViewBenefit @view="update($event)" v-else />
</template>

<script setup>
import { ref, reactive, nextTick, watch, computed, onMounted } from "vue";
import ViewBenefit from "@/components/Payroll/View/Benefits/Benefits.vue";

import { useRoute } from "vue-router";

import { storeToRefs } from "pinia";

import Deduction from "@/components/Employee/Edit/Benefits/Deduction.vue";
import BankDetails from "@/components/Employee/Edit/Benefits/BankDetails.vue";

import nigerianBanks from "@/utils/banks";
import { helperFunctions } from "@/composable/helperFunctions";

import { useToast } from "vue-toastification";
import { useDataStore } from "@/stores/data.js";
import { split } from "@apollo/client/core";

const route = useRoute();
const store = useDataStore();
const { query, mutate } = store;
const { allowance, listEmployees, listAllowance, getAllEmployees } =
  storeToRefs(store);
const toast = useToast();
const { processNumber, resetObj } = helperFunctions;
const props = defineProps({
  multipleEdit: {
    type: Boolean,
    default: false,
  },
  // edit: {
  //   type: Boolean,
  //   default: false,
  // },
  hideClose: {
    type: Boolean,
    default: false,
  },
  multipleData: {
    type: Array,
    default: [],
  },
});
const emit = defineEmits(["closeEdit", "uploadToServer"]);
const selectedAllowances = ref([]);
const allowanceToHide = ref([]);
const fixedAllowances = ref([]);
const staticAllowances = ref(["basic", "housing", "transport"]);

const edit = ref(false);
const addNew = ref(false);
const loading = ref(false);
let viewValue = ref("benefits");
let updateSuccess = ref(false);
const dataForMultipleEdit = ref([]);
const args = ref({
  allowanceDetails: [],
  salary: {
    grossAmount: 0,
    netAmount: 0,
    witholdingTax: false,
  },
  staffLevelId: "",
  usePercentageForAllowance: true,
});

function toggleScreen(param) {
  viewValue.value = param;
}
function cancelOperation() {
  edit.value = false;
  args.value = {
    allowanceDetails: [],
    salary: {
      grossAmount: 0,
      netAmount: 0,
      witholdingTax: false,
    },
    staffLevelId: "",
    usePercentageForAllowance: true,
  };
}
// watch(props.multipleEdit, () => {
//   props.multipleEdit ? (edit.value = true) : "";
// });
function cancelMultipleEdit() {
  emit("closeEdit", false);
}

function removeSymbolsFromString(string) {
  if (string && String(string).length) {
    let result = String(string).replace(/[₦%,]/g, "");
    return Number(result);
  }
  return 0;
}

function updateEmp(param) {
  if (param) {
    // console.log(param, "EORKD");
    if (dataForMultipleEdit.value.includes(param)) {
      let index = dataForMultipleEdit.value.indexOf(param);
      if (index > -1) {
        dataForMultipleEdit.value.splice(index, 1);
      }
      // console.log(param, "Removed From", dataForMultipleEdit);
    } else {
      dataForMultipleEdit.value.push(param);
      nextTick();
    }
    // emit("update", dataForMultipleEdit.value);
  }
}

function updateData() {
  // validateAllowanceThenNext();
  // transformAllowances();
  const uploadObj = {
    grossAmount: removeSymbolsFromString(args.value.salary.grossAmount),
    staffLevelIds: [],
  };
  const data = dataForMultipleEdit.value.map((dat) => {
    uploadObj.staffLevelIds.push(dat.staffLevelId);
    // dat.netPay = removeSymbolsFromString(args.value.salary.netAmount);
    // dat.allowanceDetails = args.value.allowanceDetails;
    // dat.usePercentage = args.value.usePercentageForAllowance;
    return dat;
  });

  emit("uploadToServer", uploadObj);
  emit("closeEdit", false);
}

// const usePercentageForAllowance = ref(true);
const addAllowance = ref(false);
const accountWarning = ref("");
const currentEmployee = reactive({});
const allowanceData = ref({});
const allowanceArg = reactive({
  name: "",
});
const payload = reactive({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});
function update(event) {
  edit.value = true;
  viewValue.value = event;
}
const payload2 = reactive({
  paging: {
    limit: 1000,
    skip: 0,
    search: null,
  },
});
let bankArgs = reactive({
  bankName: "",
  bankCode: "",
  accountNumber: "",
  accountName: "",
});
const bankName = ref("");
const allAllowanceData = computed(() => store.listAllowance);
const allEmployees = computed(() =>
  store.getAllEmployees ? store.getAllEmployees : { data: [] }
);
// const staticAllowances = ref([]);
const otherAllowances = ref([]);
const allowanceOptions = computed(() => {
  const data = allAllowanceData.value.data.map((da) => {
    return {
      label: da.name,
      value: da._id,
    };
  });
  const filterData = data.filter(
    (d) => !staticAllowances.value.includes(d.label)
  );
  return filterData;
});

function hideAllowance(allowanceId) {
  allowanceToHide.value.push(allowanceId);
  selectedAllowances.value = selectedAllowances.value.filter(
    (al) => !allowanceToHide.value.includes(al)
  );
}
function toggleAllowance(e) {
  // selectedAllowances.value = e;
  allowanceToHide.value = allowanceToHide.value.filter(
    (ad) => !selectedAllowances.value.includes(ad)
  );
}

function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return "";
}
function checkAllowance() {
  console.log(allowanceData.value);
}
async function queryAllowance() {
  await query({
    endpoint: "ListAllowance",
    payload: payload2,
    service: "PAYROLL",
    storeKey: "allowance",
  });
  splitAllowance();
}

function getLabelFromValue(value) {
  if (value) {
    const label = totalEmp.value.filter((item) => {
      return item.value === value;
    });

    return label[0].label;
  }
}

function getColor(index) {
  let n = (index * 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
}

const initials = computed(() => {
  return (name) => {
    let color;
    // Get color

    const arr = name.split(" ");
    let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
    const initialOne = String(arr[0]).charAt(0);
    const initialTwo = String(arr[1]).charAt(0);
    const num =
      removeSymbolsFromString(alphabet.indexOf(initialOne.toLowerCase())) +
      removeSymbolsFromString(alphabet.indexOf(initialTwo.toLowerCase()));
    color = getColor(num);
    const str = `${arr[0].charAt(0).toUpperCase()}${arr[1]
      .charAt(0)
      .toUpperCase()}`;
    return { str, color };
  };
});

async function queryEmployees() {
  await query({
    endpoint: "ListEmployees",
    payload: payload,
    service: "EMP",
    storeKey: "listEmployees",
  });
}
queryEmployees();
function getEmployeeById(id) {
  let employee = [];
  if (route.params.id) {
    employee =
      allEmployees.value &&
      allEmployees.value.data &&
      allEmployees.value.data.length &&
      allEmployees.value.data.filter((emp) => emp._id === id);
    // console.log(employee);
  }
  return employee;
}
function splitAllowance() {
  let staticAll = ["basic", "housing", "transport"];
  fixedAllowances.value = [];
  otherAllowances.value = [];

  const all = allAllowanceData.value.data.map((item) => {
    if (staticAll.includes(item.name)) {
      fixedAllowances.value.push(item);
      return "Static";
    } else {
      otherAllowances.value.push(item);
      return "Others";
    }
  });
}
const tempAllDetail = ref([]);

function assignValues() {
  if (props.multipleEdit === false) {
    const employees = getEmployeeById(route.params.id);
    const staffLevel = employees[0].staffLevel;
    args.value.salary.grossAmount = staffLevel.grossAmount;
    args.value.salary.netAmount = staffLevel.netAmount;
    args.value.usePercentageForAllowance = staffLevel.usePercentageForAllowance;
    args.value.staffLevelId = staffLevel._id;

    tempAllDetail.value = staffLevel.allowanceDetails;

    // allowanceData = staffLevel.allowanceDetails;

    if (args.value.usePercentageForAllowance) {
      allowanceData.value = staffLevel.allowanceDetails.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.allowanceId]: removeSymbolsFromString(cur.percentage || 0),
        }),
        {}
      );
      console.log(allowanceData.value);
    } else {
      allowanceData.value = staffLevel.allowanceDetails.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.allowanceId]: removeSymbolsFromString(cur.amount || 0),
        }),
        {}
      );
    }

    console.log(staffLevel.allowanceDetails);
  } else {
    args.value.salary.grossAmount = props.multipleData[0].grossPay;
    args.value.salary.netAmount = props.multipleData[0].netPay;
    args.value.usePercentageForAllowance = props.multipleData[0].usePercentage;
    // args.value.staffLevelId = staffLevel._id;

    // tempAllDetail.value = props.multipleData[0].allowanceDetails;

    // allowanceData = staffLevel.allowanceDetails;

    if (args.value.usePercentageForAllowance) {
      allowanceData.value = props.multipleData[0].allowanceDetails.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.allowanceId]: removeSymbolsFromString(cur.percentage),
        }),
        {}
      );
    } else {
      allowanceData.value = props.multipleData[0].allowanceDetails.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.allowanceId]: removeSymbolsFromString(cur.amount),
        }),
        {}
      );
    }
  }
  if (allAllowanceData.value && allAllowanceData.value.data.length) {
    for (let all of allAllowanceData.value.data) {
      if (!staticAllowances.value.includes(all.name.toLowerCase())) {
        hideAllowance(all._id);
      }
      if (removeSymbolsFromString(allowanceData.value[all._id]) > 0) {
        selectedAllowances.value.push(all._id);
        toggleAllowance(selectedAllowances.value);
      }
    }
  }
}

function validateAllowanceThenNext() {
  try {
    args.value.allowanceDetails = [];
    if (args.value.usePercentageForAllowance) {
      var total = 0;

      for (let field of Object.keys(allowanceData.value)) {
        total += removeSymbolsFromString(allowanceData.value[field]);
        console.log(total, "TOtal %");
      }
      // if (total != 100 && total != 0) {
      //   toast.error(
      //     "Allowances Percentages must sum up to 100% of Total Taxable Salary"
      //   );
      //   console.log(allowanceData.value, "%ERROR!!!!!!");

      //   throw new Error(
      //     "Allowances Percentages must sum up to 100% of Total Taxable Salary"
      //   );
      // }
      if (!total) {
        let temp;
        let temp2;
        let temp3;
        for (let field of allAllowanceData.value.data) {
          if (field.name.toLowerCase() == "basic") {
            temp = field._id;
          }
          if (field.name.toLowerCase() == "housing") {
            temp2 = field._id;
          }
          if (field.name.toLowerCase() == "transport") {
            temp3 = field._id;
          }
        }
        args.value.allowanceDetails.push(
          {
            allowanceId: temp,
            percentage: 50,
          },
          {
            allowanceId: temp2,
            percentage: 30,
          },
          {
            allowanceId: temp3,
            percentage: 20,
          }
        );
      }
    } else {
      var total = 0;

      for (let field of Object.keys(allowanceData.value)) {
        total += removeSymbolsFromString(allowanceData.value[field]);
        console.log(total, "TOtal Amount");
      }
      // if (
      //   total != removeSymbolsFromString(args.value.salary.grossAmount) &&
      //   total != 0
      // ) {
      //   toast.error("Total Allowances must equal Total Taxable Salary");
      //   console.log(allowanceData.value, "%Amount!!!!!!");

      //   throw new Error("Total Allowances must equal Total Taxable Salary");
      // }
      if (total === 0) {
        let temp;
        for (let field of allAllowanceData.value.data) {
          if (field.name == "basic") {
            temp = field._id;
          }
        }

        args.value.allowanceDetails.push({
          allowanceId: temp,
          amount: removeSymbolsFromString(args.value.salary.grossAmount),
        });
      }
    }
    return true;
  } catch (e) {
    loading.value = false;
    return false;
    console.log(e, "total error");
  }

  // bonus = true;
  // allowance = false;
}

function createAllowance() {
  if (allowanceArg.name.length) {
    loading.value = true;
    mutate({
      endpoint: "CreateAllowance",
      data: { input: allowanceArg },
      service: "PAYROLL",
    })
      .then((res) => {
        console.log(res);
        if (res.success) {
          loading.value = false;
          addAllowance.value = false;
          allowanceArg.name = "";
          selectedAllowances.value.push(res.data._id);
          // transformAllowances();
          queryAllowance();
          toggleAllowance(selectedAllowances.value);
          toast.success("Allowance Created Successfuly");
        } else {
          loading.value = false;
          toast.error(res.message);
        }
      })
      .catch((e) => {
        console.log(e);
        loading.value = false;
        toast.error("This allowance exists already");
      });
  } else if (selectedAllowances.value.length) {
    toggleAllowance(selectedAllowances.value);
    addAllowance.value = false;
  }
}
function transformAllowances() {
  for (var item in allowanceData.value) {
    if (!args.value.usePercentageForAllowance) {
      args.value.allowanceDetails.push({
        allowanceId: item,
        amount: removeSymbolsFromString(allowanceData.value[item]),
      });
    } else {
      args.value.allowanceDetails.push({
        allowanceId: item,
        percentage: removeSymbolsFromString(allowanceData.value[item]),
      });
    }
  }

  addIdToAllowanceDetails();
}

function addIdToAllowanceDetails() {
  const newAllowanceDetails = tempAllDetail.value.map((item) => {});

  for (let item of tempAllDetail.value) {
    for (let data of args.value.allowanceDetails) {
      if (item.allowanceId === data.allowanceId) {
        data["_id"] = item._id;
      } else {
        continue;
      }
    }
  }
  console.log(args.value.allowanceDetails, "FRESH ARGS");
}

async function updateAllowanceDetails() {
  loading.value = true;
  const noError = validateAllowanceThenNext();

  if (noError === false) {
    return;
  }
  transformAllowances();
  args.value.salary.grossAmount = removeSymbolsFromString(
    args.value.salary.grossAmount
  );
  args.value.salary.netAmount = removeSymbolsFromString(
    args.value.salary.netAmount
  );
  // console.log(args.value, "ARGUMENTS");

  try {
    let res = await mutate({
      endpoint: "updateAllowanceDetails",
      data: {
        input: args.value,
      },
      service: "EMP",
    });
    console.log(res);
    if (res) {
      await queryEmployees();
      loading.value = false;
      updateSuccess.value = true;
      // Object.assign(args.value, resetObj(args.value, "args"));
      setTimeout(() => {
        updateSuccess.value = false;
        window.location.reload();
      }, 500);
      // toast.success("Allowance Details Updated Successfully");
    }
  } catch (e) {
    console.log(e);
    toast.error(e.message);
    loading.value = false;
  } finally {
    loading.value = false;
  }
}

function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}
dataForMultipleEdit.value = getUniqueListBy(props.multipleData, "staffLevelId");
props.multipleEdit ? (edit.value = true) : "";
onMounted(async () => {
  await queryEmployees();
  await queryAllowance();
});

// queryEmployees();
queryAllowance();
assignValues();
</script>

<style></style>
