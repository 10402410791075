<template>
  <div class="bg-center relative w-full">
    <!-- <div class="absolute"></div> -->

    <div>
      <div class="w-full flex justify-center">
        <div class="grid grid-flow-col auto-cols-auto gap-6 items-center z-10 w-full">
          <div
            class="mx-0 xl:ml-10 md:w-full lg:w-full xl:w-10/12 flex md:flex-row flex-col justify-center rounded-2xl"
          >
            <div class="swiper-container flex-col flex self-center w-full">
              <div class="swiper-wrapper w-full">
                <div
                  v-for="(block, i) in messages"
                  :key="i"
                  class="rounded-3xl flex flex-col md:flex-row items-center md:items-start swiper-slide"
                >
                  <!-- <div
                    class="rounded-3xl overflow-hidden w-60 h-52 md:w-64 md:h-48"
                  >
                    <img
                      :src="block.img"
                      class="w-full h-full object-cover"
                      alt=""
                    />
                  </div> -->
                  <div class="w-full text-center lg:text-left">
                    <!-- <div class="relative w-full"></div> -->

                    <div class="relative md:items-start items-center gap-2 flex flex-col-reverse md:flex-col">
                      <p class="w-full text-dark-800 text-sm md:leading-7 leading-6 px-5">
                        {{ block.text }}
                      </p>

                      <div class="flex items-center gap-2 text-sm mt-2 mx-5">
                        <p class="font-bold text-dark-800 capitalize">{{ block.name }},</p>
                        <p class="text-xs text-dark-200">{{ block.designation }}.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="md:mt-12 mt-8 swiper-pagination"></div>
              <!-- <div class="swiper-button-prev"></div> -->
              <!-- <div class="swiper-button-next"></div> -->

              <svg
                class="w-4 cursor-pointer swiper-button-prev hidden xl:block -mt-20"
                width="12"
                height="23"
                viewBox="0 0 12 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.2925 12.0052L10.2925 22.0052C10.4324 22.1452 10.6106 22.2405 10.8047 22.2792C10.9988 22.3178 11.2 22.298 11.3828 22.2223C11.5656 22.1465 11.7218 22.0182 11.8317 21.8536C11.9416 21.6891 12.0002 21.4956 12 21.2977V1.29766C12.0002 1.09977 11.9416 0.906274 11.8317 0.74168C11.7218 0.577087 11.5656 0.448792 11.3828 0.37304C11.2 0.297287 10.9988 0.277483 10.8047 0.316133C10.6106 0.354784 10.4324 0.450151 10.2925 0.590163L0.2925 10.5902C0.199523 10.683 0.125764 10.7933 0.0754395 10.9147C0.025115 11.0361 -0.000785828 11.1662 -0.000785828 11.2977C-0.000785828 11.4291 0.025115 11.5592 0.0754395 11.6806C0.125764 11.802 0.199523 11.9123 0.2925 12.0052Z"
                  fill="#EA4E4B"
                />
              </svg>

              <svg
                class="w-4 cursor-pointer swiper-button-next -ml-40 -mt-20 hidden xl:block"
                width="12"
                height="23"
                viewBox="0 0 12 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.7075 12.0032L1.7075 22.0032C1.56765 22.1432 1.38939 22.2386 1.19531 22.2772C1.00122 22.3159 0.800033 22.2961 0.61721 22.2203C0.434387 22.1446 0.278151 22.0163 0.16828 21.8517C0.0584092 21.6871 -0.000155428 21.4936 3.09801e-07 21.2957V1.29571C-0.000155428 1.09781 0.0584092 0.904321 0.16828 0.739727C0.278151 0.575133 0.434387 0.446839 0.61721 0.371087C0.800033 0.295334 1.00122 0.27553 1.19531 0.31418C1.38939 0.352831 1.56765 0.448198 1.7075 0.588209L11.7075 10.5882C11.8005 10.6811 11.8742 10.7914 11.9246 10.9128C11.9749 11.0342 12.0008 11.1643 12.0008 11.2957C12.0008 11.4271 11.9749 11.5573 11.9246 11.6786C11.8742 11.8 11.8005 11.9103 11.7075 12.0032Z"
                  fill="#EA4E4B"
                />
              </svg>
            </div>
          </div>

          <!-- <svg
            class="cursor-pointer swiper-button-next -ml-40 -mt-20 hidden xl:block"
            width="12"
            height="23"
            viewBox="0 0 12 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7075 12.0032L1.7075 22.0032C1.56765 22.1432 1.38939 22.2386 1.19531 22.2772C1.00122 22.3159 0.800033 22.2961 0.61721 22.2203C0.434387 22.1446 0.278151 22.0163 0.16828 21.8517C0.0584092 21.6871 -0.000155428 21.4936 3.09801e-07 21.2957V1.29571C-0.000155428 1.09781 0.0584092 0.904321 0.16828 0.739727C0.278151 0.575133 0.434387 0.446839 0.61721 0.371087C0.800033 0.295334 1.00122 0.27553 1.19531 0.31418C1.38939 0.352831 1.56765 0.448198 1.7075 0.588209L11.7075 10.5882C11.8005 10.6811 11.8742 10.7914 11.9246 10.9128C11.9749 11.0342 12.0008 11.1643 12.0008 11.2957C12.0008 11.4271 11.9749 11.5573 11.9246 11.6786C11.8742 11.8 11.8005 11.9103 11.7075 12.0032Z"
              fill="#EA4E4B"
            />
          </svg> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import person1 from "../pages/landing/assets/person-1.png";
import person2 from "../pages/landing/assets/person-2.png";

const messages = ref([
  {
    name: "Dr. Olorunsheyi",
    text: "From the moment we engaged with Eazipay till today, we have experienced nothing but excellence, efficiency and consistency. I love the fact that the brand is very solution driven and innovative. Our company particularly makes use of the payroll feature on their app and I can tell you for free that it has made our employee management more streamlined and easy.",
    designation: "CEO Pertinence Group",
    img: person1,
  },
  {
    name: "Dr Valentine Obi",
    text: "I highly recommend Asher and his team! Their expertise and professionalism are very commendable. It is always a delight working with the Eazipay team and you can tell by the quality of staff that the leadership is exemplary.",
    designation: "Founder, E-tranzact & Xcel",
    img: person2,
  },
  {
    name: "Sanmi & Benjamen",
    text: "Our growth story would definitely not be complete without mentioning how Eazipay played an integral role in it. The support, resources, and opportunities provided by the team have been instrumental in shaping our journey, even in getting into Y Combinator. Eazipay is undoubtedly more than just a brand; it's a dependable business ally.",
    designation: "Cofounders at Shekel",
    img: person1,
  },
  {
    name: "Mr Supo",
    text: "We became clients of Eazipay in 2022 and one of the things that immediately stood out to us was how intentional the team was in ensuring we thrive and succeed as a business. We needed some enterprise loans and business advisory services and when we approached Eazipay with our expectations, not only did they efficiently deliver but they went over and beyond. I can confidently say Eazipay is a brand that cares about its customers.",
    designation: "CEO, New Era",
    img: person2,
  },
  {
    name: "Mrs. Favor",
    text: "I have had the privilege of closely working alongside Efosa and Kingsley (and recently Asher) over the years and I can say that they are exceptional people with a genuine passion to help SMEs grow and succeed.Their people skills are exceptional and more importantly their dedication and excellence shine through in everything they do. They are truly stand up individuals.",
    designation: "Group CSO, Access Bank",
    img: person1,
  },
  {
    name: "Yinka",
    text: "Running a business comes with its fair share of challenges, and handling payroll and tax payments used to be a huge task. Accuracy, compliance, and time efficiency were constant concerns until our company started using Eazipay. The Eazipay Business App has completely revolutionized the way we handle our financial responsibilities. Now we can make compliance payments with just a click and more importantly, Eazipay ensures compliance with tax regulations, relieving us of the stress associated with potential errors when handled manually.",
    designation: "CEO Plumter",
    img: person1,
  },
]);
</script>

<style>
.swiper-pagination {
  bottom: 0;
  position: relative;
  width: 100vw;
}

.swiper-container {
  overflow: hidden;
  width: 88vw !important;
  /* margin-left: -15px; */
}
.swiper-pagination-bullet {
  background-color: #ea4e4b;
}
.swiper-pagination-bullet-active {
  background-color: #ea4e4b;
}

@media (max-width: 768px) {
  .swiper-container {
    overflow: hidden;
    /* width: 100vw !important; */
    /* margin-left: 100px; */
  }
}
</style>
