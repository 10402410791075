<template>
  <main class="border-none relative">
    <div v-if="!noBack">
      <div
        class="mb-8 flex items-center gap-1 cursor-pointer"
        @click="$emit('back')"
      >
        <img class="w-5 h-5" src="@/assets/img/chevron-left.svg" alt="" />
        <span class="text-primary">Back</span>
      </div>
    </div>
    <div :class="noInput ? '' : 'h-36'">
      <Transition>
        <div v-if="showAnimation">
          <p class="text-2xl font-bold text-grey mb-6 text-left">{{ label }}</p>
          <slot name="customSlot"></slot>

          <input
            v-if="!noInput"
            ref="inputRefa"
            class="input text-4xl bg-transparent w-full font-bold caret-dark-500 outline-none border-none"
            placeholder="Type here..."
            style="
              --tw-ring-shadow: none;
              padding-left: 10px;
              border: none !important;
              overflow-wrap: break-word;
            "
            :type="type"
            :value="modelValue"
            v-bind="$attrs"
            @input="$emit('update:modelValue', $event.target.value)"
            @keypress.enter="next"
          />
          <div
            v-if="error"
            class="text-xs font-medium text-error mt-2 text-left"
          >
            {{ error }}
          </div>
          <slot></slot>

          <div
            v-if="showSlotButton == true"
            class="flex items-center gap-4"
            :class="optional ? 'mt-10' : 'mt-14'"
          >
            <easiButton
              :loading="loading"
              :disabled="disabled"
              block
              color=" newGreen"
              rounded="3xl"
              @click="next"
            >
              <div class="flex items-center justify-center space-x-3">
                {{ buttonLabel }}
              </div>
            </easiButton>

            <!-- <easiButton
              :loading="loading"
              :disabled="disabled"
              block
              size="small"
              color=" newGreen"
              rounded="3xl"
              @click="next"
            >
              <div class="flex items-center justify-center w-32 space-x-3">
                {{ buttonLabel }}
              </div>
            </easiButton> -->

            <div class="flex items-center gap-2">
              <img class="w-5 h-5" src="@/assets/img/blinkenter.svg" alt="" />
              <span class="text-newGray">
                Or Press
                <span class="font-bold text-dark-800"> Enter </span>
              </span>
            </div>
          </div>
        </div>
      </Transition>
    </div>

    <div
      v-if="!noButton && !showSlotButton"
      class="flex items-center gap-4"
      :class="optional ? 'mt-16 md:mt-10' : 'mt-14'"
    >
      <easiButton
        :loading="loading"
        :disabled="disabled"
        block
        color=" newGreen"
        rounded="3xl"
        @click="next"
      >
        <div class="flex items-center justify-center space-x-3">
          {{ buttonLabel }}
        </div>
      </easiButton>

      <div class="flex items-center gap-2">
        <img class="w-5 h-5" src="@/assets/img/blinkenter.svg" alt="" />
        <span class="text-newGray">
          Or Press
          <span class="font-bold text-dark-800"> Enter </span>
        </span>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, onMounted } from "vue";

defineProps({
  label: String,
  modelValue: String,
  type: {
    type: String,
    default: "text",
  },
  optional: Boolean,
  noBack: Boolean,
  showSlotButton: Boolean,
  noInput: Boolean,
  loading: Boolean,
  disabled: Boolean,
  noButton: Boolean,
  error: {
    type: [String, Boolean],
    default: null,
  },
  buttonLabel: {
    type: String,
    default: "Next",
  },
});
const emit = defineEmits(["next"]);
const next = () => {
  //   showAnimation.value = false;
  //   setTimeout(() => {
  emit("next");
  //   }, 500);
};
const inputRefa = ref(null);
const showAnimation = ref(false);
onMounted(() => {
  // Focus the input element when the component is mounted

  setTimeout(() => {
    showAnimation.value = true;
  }, 100);
  setTimeout(() => {
    if (inputRefa.value) {
      inputRefa.value.focus();
    }
  }, 500);
});
</script>

<style scoped>
/* Additional styles as needed */

.v-enter-active,
.v-leave-active {
  transition: 0.5s ease;
}

.v-enter-from {
  opacity: 0;
  transform: translateY(50px);
}
.v-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
</style>
