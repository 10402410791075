<template>
  <main
    class="bg-white p-3 rounded-tr-none md:rounded-xl rounded-tl-none rounded-lg"
    :class="
      !fromEmployee ? 'border border-dark-50 shadow-md  md:mt-16' : 'mt-8'
    "
  >
    <div
      v-if="!fromEmployee"
      class="bg-newAsh rounded-xl px-2 md:px-4 py-5 mb-3 flex flex-col gap-y-3"
    >
      <div class="flex w-full justify-between items-center">
        <span class="md:text-2xl text-lg text-dark-800 font-bold"
          >Personal Bio</span
        >
        <div
          v-if="route.path.includes('edit') && !isProrated"
          class="flex rounded-full bg-white p-2 cursor-pointer items-center gap-x-8"
        >
          <div
            v-if="route.path.includes('edit') && !isProrated"
            @click="
              $router.push({
                name: 'EditPersonalInfo',
                params: { id: $route.params.id },
              })
            "
            class="cursor-pointer flex items-center text-base px-3 space-x-2"
          >
            <img class="w-4" src="@/assets/icons/Edit3.svg" alt="" />

            <span class="font-medium"> Edit </span>
          </div>

          <!-- <span @click="showWarningForAllowance" class="cursor-pointer"
            ><i class="fas fa-trash text-secondary"></i>
          </span> -->
        </div>
      </div>
      <div class="flex flex-col md:flex-row gap-4 md:items-center">
        <div
          class="flex-none w-24 border-2 border-secondary h-28 md:h-60 md:w-52 rounded-lg overflow-hidden"
        >
          <img
            v-if="employee && employee.profile.pfp"
            class="w-full h-full object-cover"
            :src="employee && employee.profile.pfp"
            alt=""
          />
          <div
            v-else
            class="bg-secondary text-white text-4xl uppercase md:text-7xl font-bold flex items-center justify-center w-full h-full"
          >
            {{ getAvatar() }}
          </div>
        </div>
        <div class="flex w-full flex-col gap-2 md:gap-0 self-start">
          <div class="w-full grid grid-cols-2 md:grid-cols-5 items-start gap-2">
            <div
              class="flex bg-white rounded-xl col-span-1 p-3 w-full items-start flex-col gap-2"
            >
              <span class="text-xs md:text-sm font-medium text-newLightgrey"
                >Title</span
              >
              <span class="text-base md:text-base font-medium"
                >{{
                  display(
                    employee && employee.profile && employee.profile.title
                  )
                }}.</span
              >
            </div>
            <div
              class="flex whitespace-normal overflow-hidden flex-col bg-white md:col-span-2 rounded-xl p-3 w-full gap-2 items-start"
            >
              <span class="text-xs md:text-sm font-medium text-newLightgrey"
                >First Name</span
              >
              <span class="text-base capitalize md:text-base font-medium">{{
                display(
                  employee && employee.profile && employee.profile.firstName
                )
              }}</span>
            </div>
            <div
              class="flex flex-col bg-white col-span-2 rounded-xl p-3 w-full gap-2 items-start"
            >
              <span class="text-xs md:text-sm font-medium text-newLightgrey"
                >Last Name</span
              >
              <span class="text-base capitalize md:text-base font-medium">{{
                display(
                  employee && employee.profile && employee.profile.lastName
                )
              }}</span>
            </div>
            <div>
              <!-- <img
          src="@/assets/icons/edit.svg"
          class="cursor-pointer"
          alt=""
        /> -->
            </div>
          </div>
          <div class="w-full grid grid-cols-1 md:grid-cols-2 items-start gap-2">
            <div
              class="flex bg-white rounded-xl col-span-1 p-3 w-full items-start flex-col gap-2"
            >
              <span class="text-xs md:text-sm text-newLightgrey"
                >Job Title:</span
              >
              <span
                class="text-sm text-left font-bold md:text-base text-dark-800 md:font-medium"
                >{{
                  display(employee && employee.job && employee.job.jobTitle)
                }}</span
              >
            </div>
            <div
              class="flex bg-white rounded-xl col-span-1 p-3 w-full items-start flex-col gap-2"
            >
              <span class="text-xs md:text-sm text-newLightgrey"
                >Department:</span
              >
              <span
                class="text-sm text-left font-bold md:text-base text-dark-800 md:font-medium"
                >{{
                  display(employee && employee.job && employee.job.department)
                }}</span
              >
            </div>
            <div
              class="flex bg-white rounded-xl col-span-2 p-3 w-full items-start flex-col gap-2"
            >
              <span class="text-xs md:text-sm text-left text-newLightgrey"
                >Employment Type:</span
              >
              <span
                class="text-sm text-left font-bold md:text-base text-dark-800 md:font-medium"
                >{{
                  empTypeNams(
                    display(
                      employee && employee.job && employee.job.employmentType
                    )
                  )
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- ////Avatar//// -->
    </div>

    <div class="relative">
      <div
        class="bg-mint rounded-xl px-2 md:px-4 py-5 mb-3 flex flex-col gap-y-3"
      >
        <!-- <span
        @click="
          $router.push({
            name: $route.path.includes('edit')
              ? 'ViewSingleSalary'
              : 'EditSingleSalary',
            params: { id: $route.params.id },
          })
        "
        class="absolute top-4 right-4 cursor-pointer"
      >
        <img
          v-if="$route.path.includes('view')"
          src="@/assets/icons/edit.svg"
          alt=""
        />
        <img v-else class="" src="@/assets/icons/close.svg" alt="" />
      </span> -->

        <div class="flex flex-wrap gap-3 md:gap-0 items-center justify-between">
          <div class="flex flex-col md:p-3 items-start gap-2 rounded-xl">
            <div
              class="flex md:justify-start justify-between items-center gap-2"
            >
              <span class="md:text-xl text-lg text-dark-800 font-bold"
                >Salary Info
              </span>

              <Tag
                v-if="
                  employee &&
                  employee.prorate &&
                  employee.prorate.proratedSalary
                "
                style="width: 80px"
                class="text-xs bg-white"
              >
                <span style="color: rgba(47, 128, 237, 1)" class="text-xs"
                  >PRORATED</span
                ></Tag
              >
            </div>
          </div>
          <div
            v-if="route.path.includes('edit')"
            class="flex rounded-full bg-white p-2 cursor-pointer items-center gap-x-8"
          >
            <div
              v-if="
                employee && employee.prorate && employee.prorate.proratedSalary
              "
              @click="deleteWarningForProrate = true"
              class="cursor-pointer flex items-center text-base px-3 space-x-2"
            >
              <img class="w-4" src="@/assets/icons/orange-delete.svg" alt="" />

              <span
                style="color: rgba(239, 68, 68, 1)"
                class="text-sm font-medium"
              >
                Delete Proration
              </span>
            </div>
            <div
              v-else
              @click="updateView('benefits')"
              class="cursor-pointer flex items-center text-base px-3 space-x-2"
            >
              <img class="w-4" src="@/assets/icons/Edit3.svg" alt="" />

              <span class="font-medium"> Edit </span>
            </div>

            <!-- <span @click="showWarningForAllowance" class="cursor-pointer"
            ><i class="fas fa-trash text-secondary"></i>
          </span> -->
          </div>
        </div>

        <div class="grid md:grid-cols-4 grid-cols-2 gap-2">
          <div
            class="md:col-span-2 flex bg-white rounded-xl p-3 w-full items-start flex-col gap-2"
          >
            <span class="text-xs md:text-sm text-newLightgrey">Gross Pay</span>
            <span
              class="text-sm text-left font-bold md:text-base text-dark-800 md:font-medium"
              >{{
                formatAmount(employee && employee.staffLevel.grossAmount)
              }}</span
            >
          </div>

          <div
            class="md:col-span-2 flex bg-white rounded-xl p-3 w-full items-start flex-col gap-2"
          >
            <span class="text-xs md:text-sm text-newLightgrey">Net Pay</span>
            <span
              class="text-sm text-left font-bold md:text-base text-dark-800 md:font-medium"
              >{{
                employee && employee.prorate && netOrProrate && netOrProrate > 0
                  ? formatAmount(netOrProrate)
                  : formatAmount(employee && employee.staffLevel.netAmount)
              }}</span
            >
          </div>

          <div
            v-for="allowance in benefits"
            :key="allowance.allowanceId"
            class="col-span-1 flex bg-white rounded-xl col-span-1 p-3 w-full items-start flex-col gap-2"
          >
            <span class="text-xs md:text-sm capitalize text-newLightgrey"
              >{{ allowance && allowance.name }}:</span
            >
            <span
              class="text-sm text-left font-bold md:text-base text-dark-800 md:font-medium"
              >{{
                formatAmount(
                  allowance && removeSymbolsFromString(allowance.amount)
                )
              }}</span
            >
          </div>
        </div>
      </div>
      <!-- ///Bonus/// -->
      <div class="grid grid-cols-1 rounded-xl mb-5 py-4 bg-newAsh px-2 md:px-4">
        <div
          class="text-lg md:text-xl font-bold mb-2 text-dark-800 flex items-center justify-between"
        >
          Bonus
        </div>
        <div class="grid grid-cols-1 md:grid-cols-4 gap-2">
          <div
            v-for="bonus in employee.bonusOrDeductions"
            :key="bonus._id"
            v-show="bonus.type && bonus.type.toLowerCase() == 'bonus'"
            class="md:col-span-2 flex bg-white rounded-xl p-3 w-full items-start flex-col gap-2"
          >
            <span class="text-xs md:text-sm capitalize text-newLightgrey">{{
              bonus.name
            }}</span>
            <div class="flex items-center gap-2">
              <span class="text-sm text-dark-800 font-medium"
                >{{ bonus.amount ? "Amount" : "Percentage" }}:</span
              >
              <span class="text-base text-dark-800 font-bold">
                {{
                  bonus.amount ? formatAmount(bonus.amount) : bonus.percentage
                }}</span
              >
            </div>
            <!-- <span
              class="text-sm text-left font-bold md:text-base text-dark-800 md:font-medium"
              >{{
                bonus.amount ? formatAmount(bonus.amount) : bonus.percentage
              }}</span
            > -->
          </div>
        </div>
      </div>
      <!-- ////Statutory Deduction ///// -->

      <div class="grid grid-cols-1 bg-newAsh rounded-xl mb-5">
        <div class="flex items-center justify-between mb-3 md:mb-0 pb-0 p-4">
          <div
            class="text-lg font-bold text-dark-800 flex items-center justify-between"
          >
            Statutory Deductions
          </div>
          <div
            v-if="route.path.includes('edit') && !isProrated"
            class="flex rounded-full cursor-pointer rouunded-full bg-white p-2 items-center gap-x-8"
          >
            <div
              v-if="route.path.includes('edit') && !isProrated"
              @click="updateView('deductions')"
              class="cursor-pointer flex items-center text-base px-3 space-x-2"
            >
              <img class="w-4" src="@/assets/icons/Edit3.svg" alt="" />

              <span class="font-medium"> Edit </span>
            </div>

            <!-- <span @click="showWarningForAllowance" class="cursor-pointer"
            ><i class="fas fa-trash text-secondary"></i>
          </span> -->
          </div>
        </div>

        <div class="px-2 sm:py-4 grid grid-cols-1 gap-4">
          <div
            style="background: rgba(255, 255, 255, 0.6)"
            class="grid md:grid-cols-2 rounded-xl grid-cols-1 p-2 gap-3"
            v-if="
              employee &&
              employee.staffLevel.paye &&
              employee.staffLevel.paye.amount
            "
          >
            <div class="md:col-span-2 flex items-center gap-x-4">
              <div class="font-medium text-secondary text-base">PAYE</div>
              <input
                v-if="route.path.includes('edit') && !isProrated"
                class="hidden"
                :checked="selectedDeductions.includes('paye')"
                @change="selectDeduction('paye')"
                type="checkbox"
                name=""
                id=""
              />
            </div>
            <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-500">PAYE ID:</span>
              <span class="text-base text-dark-800 font-medium"
                >{{ employee && employee.staffLevel.paye.taxId }}
              </span>
            </div>
            <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-500">Amount:</span>
              <span class="text-base text-dark-800 font-medium">
                {{
                  formatAmount(employee && employee.staffLevel.paye.amount)
                }}</span
              >
            </div>
          </div>

          <!-- ///PENSION//// -->
          <div
            style="background: rgba(255, 255, 255, 0.6)"
            class="grid md:grid-cols-2 grid-cols-1 gap-3 rounded-xl p-2"
            new
            v-if="
              employee &&
              employee.staffLevel.pension &&
              employee.staffLevel.pension.amount
            "
          >
            <div class="md:col-span-2 flex items-center gap-x-4">
              <div class="font-medium text-secondary text-base">Pension</div>
              <input
                v-if="route.path.includes('edit') && !isProrated"
                class="hidden"
                :checked="selectedDeductions.includes('pension')"
                @change="selectDeduction('pension')"
                type="checkbox"
                name=""
                id=""
              />
            </div>
            <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-500">Provider:</span>
              <span class="text-base text-dark-800 font-medium">
                {{
                  employee &&
                  employee.staffLevel.pension &&
                  employee.staffLevel.pension.provider
                }}</span
              >
            </div>
            <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-500">ID:</span>
              <span class="text-base text-dark-800 font-medium">
                {{
                  employee &&
                  employee.staffLevel.pension &&
                  employee.staffLevel.pension.employeePensionPin
                }}</span
              >
            </div>
            <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-500">Amount:</span>
              <span class="text-base text-dark-800 font-medium">
                {{
                  formatAmount(
                    employee &&
                      employee.staffLevel.pension &&
                      employee.staffLevel.pension.amount
                  )
                }}</span
              >
            </div>

            <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-800 font-medium"
                >Employee pension Amount:</span
              >
              <span class="text-base text-dark-800 font-medium">
                {{
                  formatAmount(
                    employee &&
                      employee.staffLevel.pension &&
                      employee.staffLevel.pension.employeePensionContribution
                  )
                }}</span
              >
            </div>

            <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-800 font-medium"
                >Employer pension Amount:</span
              >
              <span class="text-base text-dark-800 font-medium">
                {{
                  formatAmount(
                    employee &&
                      employee.staffLevel.pension &&
                      employee.staffLevel.pension.employerPensionContribution
                  )
                }}</span
              >
            </div>
          </div>

          <!-- ///NFH//// -->
          <div
            style="background: rgba(255, 255, 255, 0.6)"
            class="grid md:grid-cols-2 grid-cols-1 gap-3 rounded-xl p-2"
            v-if="
              employee &&
              employee.staffLevel.nhf &&
              employee.staffLevel.nhf.amount
            "
          >
            <div class="md:col-span-2 flex items-center gap-x-4">
              <div class="font-medium text-secondary text-base">NHF</div>
              <input
                v-if="route.path.includes('edit') && !isProrated"
                class="hidden"
                :checked="selectedDeductions.includes('nhf')"
                @change="selectDeduction('nhf')"
                type="checkbox"
                name=""
                id=""
              />
            </div>
            <!-- <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-500">Code:</span>
              <span class="text-base text-dark-800 font-medium"></span>
            </div> -->
            <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-500">Amount:</span>
              <span class="text-base text-dark-800 font-medium">
                {{
                  formatAmount(
                    employee &&
                      employee.staffLevel.nhf &&
                      employee.staffLevel.nhf.amount
                  )
                }}</span
              >
            </div>
          </div>
          <!-- ///HMO//// -->
          <div
            style="background: rgba(255, 255, 255, 0.6)"
            class="grid md:grid-cols-2 grid-cols-1 gap-3 rounded-xl p-2"
            v-if="
              employee &&
              employee.staffLevel.hmo &&
              employee.staffLevel.hmo.amount
            "
          >
            <div class="md:col-span-2 flex items-center gap-x-4">
              <div class="font-medium text-secondary text-base">HMO</div>
              <input
                v-if="route.path.includes('edit') && !isProrated"
                class="hidden"
                :checked="selectedDeductions.includes('hmo')"
                @change="selectDeduction('hmo')"
                type="checkbox"
                name=""
                id=""
              />
            </div>
            <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-500">Provider:</span>
              <span class="text-base text-dark-800 font-medium">
                {{
                  employee &&
                  employee.staffLevel.hmo &&
                  employee.staffLevel.hmo.provider
                }}</span
              >
            </div>
            <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-500">Amount:</span>
              <span class="text-base text-dark-800 font-medium">
                {{
                  formatAmount(
                    employee &&
                      employee.staffLevel.hmo &&
                      employee.staffLevel.hmo.amount
                  )
                }}</span
              >
            </div>
          </div>
          <!-- ///LIFE INSURANCE//// -->
          <div
            style="background: rgba(255, 255, 255, 0.6)"
            class="grid md:grid-cols-2 grid-cols-1 gap-3 rounded-xl p-2"
            v-if="
              employee &&
              employee.staffLevel.lifeInsurance &&
              employee.staffLevel.lifeInsurance.amount
            "
          >
            <div class="md:col-span-2 flex items-center gap-x-4">
              <div class="font-medium text-secondary text-base">
                Life Insurance
              </div>
              <input
                v-if="route.path.includes('edit') && !isProrated"
                class="hidden"
                :checked="selectedDeductions.includes('lifeInsurance')"
                @change="selectDeduction('lifeInsurance')"
                type="checkbox"
                name=""
                id=""
              />
            </div>
            <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-500">Provider:</span>
              <span class="text-base text-dark-800 font-medium">
                {{
                  employee &&
                  employee.staffLevel.lifeInsurance &&
                  employee.staffLevel.lifeInsurance.provider
                }}</span
              >
            </div>
            <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-500">Duration:</span>
              <span class="text-base text-dark-800 font-medium">
                {{
                  employee &&
                  employee.staffLevel.lifeInsurance &&
                  employee.staffLevel.lifeInsurance.duration
                }}</span
              >
            </div>
            <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-500">Amount:</span>
              <span class="text-base text-dark-800 font-medium">
                {{
                  formatAmount(
                    employee &&
                      employee.staffLevel.lifeInsurance &&
                      employee.staffLevel.lifeInsurance.amount
                  )
                }}</span
              >
            </div>
          </div>

          <!-- ///LIFE ASSURANCE//// -->
          <div
            style="background: rgba(255, 255, 255, 0.6)"
            class="grid md:grid-cols-2 grid-cols-1 gap-3 rounded-xl p-2"
            v-if="
              employee &&
              employee.staffLevel.lifeAssurance &&
              employee.staffLevel.lifeAssurance.amount
            "
          >
            <div class="md:col-span-2 flex items-center gap-x-4">
              <div class="font-medium text-secondary text-base">
                Life Assurance
              </div>
              <input
                v-if="route.path.includes('edit') && !isProrated"
                class="hidden"
                :checked="selectedDeductions.includes('lifeAssurance')"
                @change="selectDeduction('lifeAssurance')"
                type="checkbox"
                name=""
                id=""
              />
            </div>
            <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-500">Provider:</span>
              <span class="text-base text-dark-800 font-medium">
                {{
                  employee &&
                  employee.staffLevel.lifeAssurance &&
                  employee.staffLevel.lifeAssurance.provider
                }}</span
              >
            </div>
            <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-500">Duration:</span>
              <span class="text-base text-dark-800 font-medium">
                {{
                  employee &&
                  employee.staffLevel.lifeAssurance &&
                  employee.staffLevel.lifeAssurance.duration
                }}</span
              >
            </div>
            <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-500">Amount:</span>
              <span class="text-base text-dark-800 font-medium">
                {{
                  formatAmount(
                    employee &&
                      employee.staffLevel.lifeAssurance &&
                      employee.staffLevel.lifeAssurance.amount
                  )
                }}</span
              >
            </div>
          </div>

          <!-- ///NSITF//// -->
          <div
            style="background: rgba(255, 255, 255, 0.6)"
            class="grid md:grid-cols-2 grid-cols-1 gap-3 rounded-xl p-2"
            v-if="
              employee &&
              employee.staffLevel.nsitf &&
              employee.staffLevel.nsitf.amount
            "
          >
            <div class="md:col-span-2 flex items-center gap-x-4">
              <div class="font-medium text-secondary text-base">NSITF</div>
              <input
                v-if="route.path.includes('edit') && !isProrated"
                class="hidden"
                :checked="selectedDeductions.includes('nsitf')"
                @change="selectDeduction('nsitf')"
                type="checkbox"
                name=""
                id=""
              />
            </div>

            <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-500">Amount:</span>
              <span class="text-base text-dark-800 font-medium">
                {{
                  formatAmount(
                    employee &&
                      employee.staffLevel.nsitf &&
                      employee.staffLevel.nsitf.amount
                  )
                }}</span
              >
            </div>
          </div>
          <!--ITF-->
          <div
            style="background: rgba(255, 255, 255, 0.6)"
            class="grid md:grid-cols-2 grid-cols-1 gap-3 rounded-xl p-2"
            v-if="
              employee &&
              employee.staffLevel.itf &&
              employee.staffLevel.itf.amount
            "
          >
            <div class="md:col-span-2 flex items-center gap-x-4">
              <div class="font-medium text-secondary text-base">ITF</div>
              <input
                v-if="route.path.includes('edit') && !isProrated"
                class="hidden"
                :checked="selectedDeductions.includes('itf')"
                @change="selectDeduction('itf')"
                type="checkbox"
                name=""
                id=""
              />
            </div>

            <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-500">Amount:</span>
              <span class="text-base text-dark-800 font-medium">
                {{
                  formatAmount(
                    employee &&
                      employee.staffLevel.itf &&
                      employee.staffLevel.itf.amount
                  )
                }}</span
              >
            </div>
          </div>

          <div
            style="background: rgba(255, 255, 255, 0.6)"
            class="grid md:grid-cols-2 grid-cols-1 gap-3 rounded-xl p-2"
            v-if="
              employee &&
              employee.staffLevel.wht &&
              employee.staffLevel.wht.amount
            "
          >
            <div class="md:col-span-2 flex items-center gap-x-4">
              <div class="font-medium text-secondary text-base">WHT</div>
              <input
                v-if="route.path.includes('edit') && !isProrated"
                class="hidden"
                :checked="selectedDeductions.includes('wht')"
                @change="selectDeduction('wht')"
                type="checkbox"
                name=""
                id=""
              />
            </div>

            <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-500">Amount:</span>
              <span class="text-base text-dark-800 font-medium">
                {{
                  formatAmount(
                    employee &&
                      employee.staffLevel.wht &&
                      employee.staffLevel.wht.amount
                  )
                }}</span
              >
            </div>

            <div
              v-if="
                employee.staffLevel &&
                employee.staffLevel.wht &&
                employee.staffLevel.wht.percentage &&
                Number(employee.staffLevel.wht.percentage) > 0
              "
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-500">Percentage:</span>
              <span class="text-base text-dark-800 font-medium">
                {{ employee.staffLevel.wht.percentage }}</span
              >
            </div>
            <div
              class="flex flex-col p-3 items-start gap-2 bg-white rounded-xl"
            >
              <span class="text-sm text-dark-500">Payment Types:</span>
              <span class="text-base text-dark-800 font-medium">
                {{ employee.staffLevel.wht.paymentTypes.join(",") }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <!-- ///Deduction/// -->

      <div class="grid grid-cols-1 rounded-xl mb-5 py-4 bg-newAsh px-2 md:px-4">
        <div
          class="text-lg md:text-xl font-bold mb-3 text-dark-800 flex items-center justify-between"
        >
          Deductions
        </div>
        <div class="grid grid-cols-1 md:grid-cols-4 gap-2">
          <div
            v-for="deduction in employee.bonusOrDeductions"
            v-show="
              deduction.type && deduction.type.toLowerCase() == 'deduction'
            "
            :key="deduction._id"
            class="col-span-2 flex bg-white rounded-xl col-span-1 p-3 w-full items-start flex-col gap-2"
          >
            <span class="text-xs md:text-sm capitalize text-newLightgrey">{{
              deduction.name
            }}</span>
            <div class="flex items-center gap-2">
              <span class="text-sm text-dark-800 font-medium"
                >{{ deduction.amount ? "Amount" : "Percentage" }}:</span
              >
              <span class="text-base text-dark-800 font-bold">
                {{
                  deduction.amount
                    ? formatAmount(deduction.amount)
                    : deduction.percentage
                }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <!-- //// Bank ///// -->
      <div
        class="grid grid-cols-1 gap-y-4 rounded-xl mb-5 py-4 bg-newAsh px-2 md:px-4"
      >
        <div class="flex items-center justify-between">
          <div
            class="text-lg md:text-xl capitalize font-bold mb-3 text-dark-800 flex items-center justify-between"
          >
            Bank details
          </div>
          <div
            v-if="route.path.includes('edit') && !isProrated"
            class="flex rounded-full bg-white p-2 cursor-pointer items-center gap-x-8"
          >
            <div
              v-if="route.path.includes('edit') && !isProrated"
              @click="updateView('bank')"
              class="cursor-pointer flex items-center text-base px-3 space-x-2"
            >
              <img class="w-4" src="@/assets/icons/Edit3.svg" alt="" />

              <span class="font-medium"> Edit </span>
            </div>

            <!-- <span @click="showWarningForAllowance" class="cursor-pointer"
            ><i class="fas fa-trash text-secondary"></i>
          </span> -->
          </div>
        </div>

        <div class="grid md:grid-cols-3 gap-2">
          <div
            class="col-span-1 flex bg-white rounded-xl col-span-1 p-3 w-full items-start flex-col gap-2"
          >
            <span class="text-xs md:text-sm capitalize text-newLightgrey"
              >Bank Name</span
            >
            <span
              class="text-sm text-left font-bold md:text-base text-dark-800 md:font-medium"
              >{{ employee && employee.payment.bankName }}</span
            >
          </div>

          <div
            class="col-span-1 flex bg-white rounded-xl col-span-1 p-3 w-full items-start flex-col gap-2"
          >
            <span class="text-xs md:text-sm capitalize text-newLightgrey"
              >Account Number</span
            >
            <span
              class="text-sm text-left font-bold md:text-base text-dark-800 md:font-medium"
              >{{ employee && employee.payment.accountNumber }}</span
            >
          </div>

          <div
            class="col-span-1 flex bg-white rounded-xl col-span-1 p-3 w-full items-start flex-col gap-2"
          >
            <span class="text-xs md:text-sm capitalize text-newLightgrey"
              >Account Name</span
            >
            <span
              class="text-sm text-left capitalize font-bold md:text-base text-dark-800 md:font-medium"
              >{{ employee && employee.payment.accountName }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <easiWarn v-if="deleteWarning === true" @close="deleteWarning = false">
      <template v-slot:message>
        <span
          >You are about to delete deductions for this employee, please note
          that this action is irreversible</span
        >
      </template>
      <template v-slot:action>
        <div class="flex flex-col w-full items-center gap-4">
          <div class="px-0 text-sm font-bold text-dark-800 leading-6">
            Do you wish to continue?
          </div>

          <div class="flex items-center w-full gap-x-4">
            <easiButton
              class="w-full"
              @click="deleteWarning = false"
              variant="outlined"
              >No</easiButton
            >
            <easiButton
              class="w-full"
              :loading="loading"
              @click="deleteDeductions"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>

    <easiWarn
      v-if="deleteWarningForAllowance === true"
      @close="deleteWarningForAllowance = false"
    >
      <template v-slot:message>
        <span
          >You are about to delete allowance(s) for this employee, please note
          that this action is irreversible</span
        >
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="px-0 text-sm font-bold text-dark-800 leading-6">
            Do you wish to continue?
          </div>

          <div class="flex items-center w-full gap-x-4">
            <easiButton
              @click="deleteWarningForAllowance = false"
              variant="outlined"
              >No</easiButton
            >
            <easiButton :loading="loading" @click="deleteAllowanceDetails"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>

    <easiWarn
      v-if="deleteWarningForProrate === true"
      @close="deleteWarningForProrate = false"
    >
      <template v-slot:message>
        <span class="text-lg"
          >You are about to delete proration for this employee, please note that
          this action is irreversible</span
        >
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="px-0 text-sm font-bold text-dark-800 leading-6">
            Do you wish to continue?
          </div>

          <div class="flex md:flex-row flex-col items-center w-full gap-4">
            <easiButton
              @click="deleteWarningForProrate = false"
              variant="outlined"
              class="md:w-40 w-72"
              >No</easiButton
            >
            <easiButton
              class="md:w-40 w-72"
              :loading="deleteProration"
              @click="deleteProrate(employee)"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Employee deduction(s) deleted successfully</span>
      </template>
    </easiSuccess>
    <easiSuccess
      v-if="updateAllowanceSuccess"
      @close="updateAllowanceSuccess = false"
    >
      <template v-slot:message>
        <span>Employee allowance(s) deleted successfully</span>
      </template>
    </easiSuccess>

    <easiRoundLoader v-if="deleteProration" />
  </main>
</template>

<script setup>
import { computed, reactive, ref, onMounted } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";

import { storeToRefs } from "pinia";
import banks from "@/utils/banks";

const props = defineProps({
  type: {
    type: String,
    default: "view",
  },

  fromEmployee: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["view"]);

const { formatAmount, removeDuplicates } = helperFunctions;
const store = useDataStore();
const route = useRoute();
const toast = useToast();

const { listAllowance, getBonusAndDeduction } = storeToRefs(store);
const { query, mutate } = store;

const bonusDeduction = computed(() => store.getBonusAndDeduction);
const employee = computed(() => store.getEmployeeById(route.params.id));
const isProrated = computed(() => {
  if (
    employee.value &&
    employee.value.prorate &&
    employee.value.prorate.proratedSalary
  ) {
    return true;
  }
  return false;
});
const netOrProrate = computed(() => {
  if (
    employee.value &&
    employee.value.prorate &&
    Array.isArray(employee.value.prorate) &&
    employee.value.prorate.length &&
    employee.value.prorate[0].staffLevel
  ) {
    return employee.value.prorate[0].staffLevel.netAmount;
  } else if (
    employee.value &&
    employee.value.prorate &&
    !Array.isArray(employee.value.prorate) &&
    employee.value.prorate.staffLevel
  ) {
    return employee.value.prorate.staffLevel.netAmount;
  } else {
    return 0;
  }
});
const deleteProration = ref(false);
// console.log(employee.value, route);
const staffLevelId = employee.value && employee.value.staffLevel._id;
const bonusData = ref([]);
const deductionData = ref([]);

const arr = [
  "paye",
  "pension",
  "nhf",
  "nsitf",
  "itf",
  "lifeInsurance",
  "lifeAssurance",
  "hmo",
];
function getMonthByIndex(index) {
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[index];
}

function empTypeNams(empType) {
  switch (empType) {
    case "FULLTIME":
      return "Full Time";
    case "CONTRACT":
      return "Contract";
    case "PARTTIME":
      return "Part Time";
    case "VOLUNTEER":
      return "Volunteer";
    case "NYSC":
      return "NYSC";
    case "INTERNSHIP":
      return "Internship";
    case "APPRENTICE":
      return "Apprentice";
    default:
      return "N/A";
  }
}

const loading = ref(false);
const staticAllowances = ref(["basic", "housing", "transport"]);
const updateSuccess = ref(false);
const updateAllowanceSuccess = ref(false);
const deleteWarning = ref(false);
const deleteWarningForAllowance = ref(false);
const selectedDeductions = ref([]);
const deduction = ref([]);

const selectedAllowances = ref([]);
const allowances = ref([]);

function removeSymbolsFromString(string) {
  if (string && String(string).length) {
    let result = String(string).replace(/[₦%,]/g, "");
    return Number(result);
  }
  return 0;
}

const getAvatar = () => {
  let name = "";

  employee
    ? (name = `${
        employee.value &&
        employee.value.profile.firstName &&
        employee.value.profile.firstName.charAt(0)
      }${
        employee.value &&
        employee.value.profile.lastName &&
        employee.value.profile.lastName.charAt(0)
      }`)
    : null;

  return name;
};

const display = (arg) => {
  return arg ? arg : "N/A";
};

const selectAllowance = (id) => {
  if (selectedAllowances.value.includes(id)) {
    let index = selectedAllowances.value.indexOf(id);
    if (index > -1) {
      selectedAllowances.value.splice(index, 1);
    }
  } else {
    selectedAllowances.value.push(id);
  }
};

const selectDeduction = (ben) => {
  if (selectedDeductions.value.includes(ben)) {
    let index = selectedDeductions.value.indexOf(ben);
    if (index > -1) {
      selectedDeductions.value.splice(index, 1);
    }
  } else {
    selectedDeductions.value.push(ben);
  }
};

const showWarningForAllowance = () => {
  if (selectedAllowances.value.length) {
    deleteWarningForAllowance.value = true;
  } else {
    toast.error("You have to select at least one allowance to be deleted.");
  }
};
const deleteWarningForProrate = ref(false);
const showWarningForProration = () => {
  deleteWarningForProrate.value = true;
};

const showWarning = () => {
  if (selectedDeductions.value.length) {
    deleteWarning.value = true;
  } else {
    toast.error("You have to select at least one deduction to be deleted.");
  }
};

const processDeductions = () => {
  deduction.value = selectedDeductions.value.map((item) => {
    if (item === "paye") return "PAYE";
    if (item === "pension") return "Pension";
    if (item === "nhf") return "NHF";
    if (item === "nsitf") return "NSITF";
    if (item === "itf") return "ITF";
    if (item === "wht") return "WHT";
    if (item === "lifeInsurance") return "Group_Life_Insurance";
    if (item === "lifeAssurance") return "Employee_Life_Assurance";
    if (item === "hmo") return "HMO";
    if (item === "businessExpense") return "Business_Expense";
    if (item === "gratuity") return "Gratuity";
  });
};

const payload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});

const queryEmployees = async () => {
  // loading.value.table = true;
  try {
    await query({
      endpoint: "ListEmployees",
      payload: payload.value,
      service: "EMP",
      storeKey: "listEmployees",
    });
  } catch (err) {
    // loading.value.table = false;
    console.log(err);
  }
};

const deleteDeductions = async () => {
  try {
    processDeductions();
    loading.value = true;
    let res = await mutate({
      endpoint: "DeleteDeductions",
      service: "PAYROLL",
      data: {
        input: {
          deduction: deduction.value,
          staffLevelId,
        },
      },
    });

    if (res.success) {
      await queryEmployees();
      deleteWarning.value = false;
      updateSuccess.value = true;
      setTimeout(() => {
        updateSuccess.value = false;
      }, 1000);
      // toast.success("Bank Record Updated Successfully");
    }
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
};

const deleteAllowanceDetails = () => {
  loading.value = true;
  mutate({
    endpoint: "deleteAllowanceDetails",
    service: "EMP",
    data: {
      input: {
        allowanceDetailsIds: selectedAllowances.value,
        staffLevelId,
      },
    },
  })
    .then((res) => {
      console.log(res, "RES");
      if (res[0]._id.length) {
        deleteWarningForAllowance.value = false;
        updateAllowanceSuccess.value = true;
        loading.value = false;
        // toast.success("Bank Record Updated Successfully");
      }
    })
    .catch((e) => console.log(e));
};

const benefits = computed(() => {
  let arr = [];
  employee.value &&
    employee.value.staffLevel.allowanceDetails.forEach((el) => {
      el.name = getAllowanceName(el.allowanceId);
      el.amount = getAllowanceAmount(el);
      arr.push(el);
    });

  return removeDuplicates(arr, "name");
});
// log(employee.value);
// log(benefits.value);

const bankLogo = computed(() => {
  let bank = banks.find(
    (el) =>
      el.code === String(employee.value && employee.value.payment.bankCode)
  );

  return bank ? bank.logo : "";
});

function getAllowanceName(id) {
  const allowance = listAllowance.value.data.find((el) => el._id === id);
  return allowance ? allowance.name : "";
}

function getAllowanceAmount(allowance) {
  if (allowance.amount) {
    // return formatAmount(allowance.amount);
    return removeSymbolsFromString(allowance.amount);
  } else {
    const amount =
      (employee.value &&
        employee.value.staffLevel.grossAmount *
          removeSymbolsFromString(allowance.percentage)) / 100;
    // return formatAmount(amount);
    return amount;
  }
}

function updateView(view) {
  emit("view", view);
}
// Get rid of the proxy when console.log
function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}

const queryBonus = async () => {
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Bonus",
        },
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
    bonusData.value = bonusDeduction.value;
    console.log(getBonusAndDeduction.value, "After query", bonusData.value);
  } catch (err) {
    console.log(err);
  }
};

const queryDeduction = async () => {
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Deduction",
        },
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
    deductionData.value = bonusDeduction.value;
    // console.log(getBonusAndDeduction.value, "After query");
  } catch (err) {
    console.log(err);
  }
};

async function deleteProrate(data) {
  const { _id, prorate } = data;

  try {
    deleteProration.value = true;
    let res = await mutate({
      endpoint: "DeleteProrate",
      data: {
        input: {
          employeeIds: [_id],
          year: prorate.year,
          month: prorate.month,
        },
      },
      service: "PAYROLL",
    });
    console.log(res);
    if (res) {
      await queryEmployees();
      deleteWarningForProrate.value = false;
    }
  } catch (e) {
    console.log(e);
  } finally {
    deleteProration.value = false;
  }
}
// const deleteArr = ref([]);

// function selectBonusOrDed(id) {
//   deleteArr.value.push(id);
// }

queryBonus();
queryDeduction();
</script>

<style></style>
