<template>
  <section style="background-color: #f8f8f8" class="audience py-8 lg:py-20 relative z-2">
    <div>
      <div class="l-container">
        <div class="text-center mb-6 lg:mb-12">
          <h2 class="text-primary-deep text-xl lg:text-3xl font-bold level-2 mb-4 md:leading-10">
            Explore our range of amazing <br class="md:hidden lg:block" />
            products
          </h2>
        </div>
        <div class="md:bg-white bg-none rounded-3xl pt-8">
          <div
            class="container px-7 hidden md:flex justify-center items-center flex-nowrap gap-2 md:gap-3 w-full overflow-x-scroll"
          >
            <div
              v-for="(item, i) in items"
              :key="i"
              :class="activeItem === i ? 'bg-primary-deep text-white' : 'whiteBg text-dark-800 '"
              class="cursor-pointer flex justify-center items-center gap-2 rounded-full text-xs text-center capitalize font-medium px-2 py-3 shrinkless md:w-full"
              style="white-space: nowrap"
              @click="
                () => {
                  activeItem = i;
                  activeIndex = item.name;
                }
              "
            >
              <!-- <div
                class="w-5 h-5 flex items-center justify-center p-1 rounded-full"
                :class="activeItem === i ? 'bg-white' : 'whiteBg'"
              >
                <img class="w-10/12" :src="item.icon" alt="" />
              </div> -->
              <span class="font-medium"> {{ item.name }}</span>
            </div>
          </div>
          <div class="md:hidden flex nav-cont">
            <button @click="moveItem('prev')" class="bg-none focus:outline-none">
              <img src="./assets/CaretLeft.svg" alt="" />
            </button>

            <h3 class="nav-text">{{ activeIndex }}</h3>
            <button @click="moveItem('next')" class="bg-none focus:outline-none">
              <img src="./assets/CaretRight.svg" alt="" />
            </button>
          </div>

          <div
            class="grid grid-cols-1 md:grid-cols-2 rounded-3xl md:p-8 items-center gap-3 md:gap-6 mt-4 md:mt-4 md:mb-10"
            v-for="(data, i) in widgetData"
            :key="i"
            :class="i === activeItem ? 'block' : 'hidden'"
          >
            <!-- <h3
            class="md:col-span-2 hidden md:block font-medium uppercase text-center text-sm md:text-base text-secondary"
          >
            {{ activeIndex }}
          </h3> -->
            <div
              class="rounded-2xl md:rounded-3xl flex justify-center md:overflow-hidden relative h-64 md:h-full w-full"
              :class="i === 5 ? 'bg-lightPink' : 'bg-mint'"
            >
              <img
                :src="data.sideBanner"
                class="object-contain absolute bottom-0"
                :class="
                  [2].includes(i)
                    ? 'w-11/12'
                    : [4, 7].includes(i)
                    ? 'w-10/12 left-0'
                    : [8].includes(i)
                    ? 'left-0 w-full'
                    : [6].includes(i)
                    ? 'w-10/12 left-0  bottom-0'
                    : [3].includes(i)
                    ? 'w-8/12 mx-auto'
                    : 'w-8/12  mx-auto'
                "
                alt=""
              />

              <div
                v-show="i === 6"
                class="md:w-32 rounded-xl mt-7 bg-white px-2 md:px-4 py-2 right-2 md:right-0 absolute"
              >
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.8246 1.11545V5.12259C11.8246 5.19344 11.7965 5.26139 11.7464 5.31149C11.6963 5.36159 11.6283 5.38974 11.5575 5.38974H6.48175V0.314018C6.48175 0.243167 6.50989 0.175219 6.55999 0.125119C6.61009 0.0750204 6.67804 0.046875 6.74889 0.046875H10.756C11.0394 0.046875 11.3112 0.159456 11.5116 0.359853C11.712 0.560249 11.8246 0.832044 11.8246 1.11545ZM5.14603 0.046875H1.13888C0.855482 0.046875 0.583686 0.159456 0.38329 0.359853C0.182894 0.560249 0.0703125 0.832044 0.0703125 1.11545V5.12259C0.0703125 5.19344 0.0984579 5.26139 0.148557 5.31149C0.198656 5.36159 0.266605 5.38974 0.337456 5.38974H5.41317V0.314018C5.41317 0.243167 5.38503 0.175219 5.33493 0.125119C5.28483 0.0750204 5.21688 0.046875 5.14603 0.046875ZM11.5575 6.45831H6.48175V11.534C6.48175 11.6049 6.50989 11.6728 6.55999 11.7229C6.61009 11.773 6.67804 11.8012 6.74889 11.8012H10.756C11.0394 11.8012 11.3112 11.6886 11.5116 11.4882C11.712 11.2878 11.8246 11.016 11.8246 10.7326V6.72545C11.8246 6.6546 11.7965 6.58665 11.7464 6.53655C11.6963 6.48645 11.6283 6.45831 11.5575 6.45831ZM0.0703125 6.72545V10.7326C0.0703125 11.016 0.182894 11.2878 0.38329 11.4882C0.583686 11.6886 0.855482 11.8012 1.13888 11.8012H5.14603C5.21688 11.8012 5.28483 11.773 5.33493 11.7229C5.38503 11.6728 5.41317 11.6049 5.41317 11.534V6.45831H0.337456C0.266605 6.45831 0.198656 6.48645 0.148557 6.53655C0.0984579 6.58665 0.0703125 6.6546 0.0703125 6.72545Z"
                    fill="#EA4E4B"
                  />
                </svg>
                <p class="text-dark-800 mt-2 text-xs leading-2">
                  Track Apps, <br />
                  Data and Security
                </p>
              </div>
            </div>
            <div
              style="border-radius: 24px; background: #f8f8f8"
              class="w-full md:h-full flex flex-col gap-5 p-6 md:border border-newAsh"
            >
              <!-- <h4 class="uppercase text-sm md:text-base text-secondary">
              payroll
            </h4> -->
              <h3
                class="md:col-span-2 hidden md:block font-medium uppercase text-left text-sm md:text-base text-secondary"
              >
                {{ activeIndex }}
              </h3>
              <h3 class="text-primary-deep font-bold text-xl md:text-xl">
                {{ data.header }}
              </h3>

              <div v-for="i in data.list" :key="i" class="flex items-center w-84 gap-2 rounded-full">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 0.25C8.07164 0.25 6.18657 0.821828 4.58319 1.89317C2.97982 2.96451 1.73013 4.48726 0.992179 6.26884C0.254225 8.05042 0.061142 10.0108 0.437348 11.9021C0.813554 13.7934 1.74215 15.5307 3.10571 16.8943C4.46928 18.2579 6.20656 19.1865 8.09787 19.5627C9.98919 19.9389 11.9496 19.7458 13.7312 19.0078C15.5127 18.2699 17.0355 17.0202 18.1068 15.4168C19.1782 13.8134 19.75 11.9284 19.75 10C19.7473 7.41498 18.7192 4.93661 16.8913 3.10872C15.0634 1.28084 12.585 0.25273 10 0.25ZM14.2806 8.28063L9.03063 13.5306C8.96097 13.6004 8.87826 13.6557 8.78721 13.6934C8.69616 13.7312 8.59857 13.7506 8.5 13.7506C8.40144 13.7506 8.30385 13.7312 8.2128 13.6934C8.12175 13.6557 8.03903 13.6004 7.96938 13.5306L5.71938 11.2806C5.57865 11.1399 5.49959 10.949 5.49959 10.75C5.49959 10.551 5.57865 10.3601 5.71938 10.2194C5.86011 10.0786 6.05098 9.99958 6.25 9.99958C6.44903 9.99958 6.6399 10.0786 6.78063 10.2194L8.5 11.9397L13.2194 7.21937C13.2891 7.14969 13.3718 7.09442 13.4628 7.0567C13.5539 7.01899 13.6515 6.99958 13.75 6.99958C13.8486 6.99958 13.9461 7.01899 14.0372 7.0567C14.1282 7.09442 14.2109 7.14969 14.2806 7.21937C14.3503 7.28906 14.4056 7.37178 14.4433 7.46283C14.481 7.55387 14.5004 7.65145 14.5004 7.75C14.5004 7.84855 14.481 7.94613 14.4433 8.03717C14.4056 8.12822 14.3503 8.21094 14.2806 8.28063Z"
                    fill="#B4A572"
                  />
                </svg>

                <p class="text-dark-800 text-sm">{{ i }}</p>
              </div>

              <div :class="i === 6 ? 'flex-col-reverse' : 'flex-col'" class="flex gap-7 mt-3">
                <div v-if="data.href">
                  <a
                    class="cursor-pointer cta rounded-full py-3 px-5 focus:outline-none shadow-lg text-sm"
                    :href="data.href"
                    target="_blank"
                    >{{ data.cta.text }}</a
                  >
                </div>
                <div v-else>
                  <router-link
                    :to="data.cta.link"
                    class="cta rounded-full py-3 px-5 focus:outline-none shadow-lg text-sm"
                  >
                    {{ data.cta.text }}
                  </router-link>
                </div>

                <div v-if="data.partners" class="flex items-center gap-6">
                  <div v-for="(img, i) in data.partners" :key="i" class="rounded-xl">
                    <img :class="[i !== 0 ? 'w-20' : 'w-16', i === 2 ? 'ml-4' : undefined]" :src="img" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";

import iPhoneX from "../landing/assets/iPhoneX.webp";
import mapImage from "../landing/assets/map-image.webp";
import taxClearanceImage from "../landing/assets/tax-clearance-image.webp";
import loansImage from "../landing/assets/loans-image.webp";
import payrollApi from "../landing/assets/payroll-apis.webp";
import WorknProsper from "../landing/assets/worknprosper.webp";
import deviceMgt from "../landing/assets/device-management.webp";
import hrStores from "../landing/assets/hr-stores.webp";
import assessment from "../landing/assets/assessment.webp";

import netsLogistics from "@/www/pages/landing/assets/nets-logistics.webp";
import shekelMobility from "@/www/pages/landing/assets/shekel-mobility.webp";
import pertinenceGroup from "@/www/pages/landing/assets/pertinence-group.webp";

import payrollIcon from "../landing/assets/payroll-icon.svg";
import payrollIconPlain from "../landing/assets/payroll-icon-plain.svg";
import storesIcon from "../landing/assets/stores-icon.svg";
import deviceIcon from "../landing/assets/device-icon.svg";
import assessmentIcon from "../landing/assets/assessment-icon.svg";
import apisIcon from "../landing/assets/apis-icon.svg";
import taxIcon from "../landing/assets/tax-icon.svg";

const items = ref([
  { name: "payroll", icon: payrollIcon },
  { name: "pay compliance", icon: payrollIconPlain },
  { name: "tax clearance", icon: taxIcon },
  { name: "instant loan", icon: payrollIcon },
  { name: "payroll APIs", icon: apisIcon },
  { name: "WorknProsper", icon: payrollIcon },
  { name: "device management", icon: deviceIcon },
  { name: "HR stores", icon: storesIcon },
  { name: "assessment", icon: assessmentIcon },
]);

const activeIndex = ref("");
const activeItem = ref(0);

const moveItem = (direction) => {
  if (activeItem.value !== items.value.length - 1 && direction === "next") {
    activeItem.value = activeItem.value + 1;
  }
  if (activeItem.value !== 0 && direction === "prev") {
    activeItem.value = activeItem.value - 1;
  }
  activeIndex.value = items.value[activeItem.value].name;
};
activeIndex.value = items.value[0].name;
const widgetData = ref([
  {
    header: "Pay all your Employees at once",
    list: ["Automate to all banks in Nigeria", "All Compliance: PAYE, Pension, etc.", "Bonuses and Deductions"],
    cta: { text: "Explore payroll for businesses", link: "/business" },
    sideBanner: iPhoneX,
    partners: [netsLogistics, shekelMobility, pertinenceGroup],
  },
  {
    header: "Compliance payments in all 36 states + FCT",
    list: [
      "Pay only what you want to pay",
      "Register and remit PAYE with all 36 states",
      "Register for Pension with PENCOM and remit to any PFA",
      "Register & Pay: Withholding Tax, ITF, NSITF, and NHF.",
    ],
    cta: { text: "See Eazipay Compliance", link: "/paye" },
    sideBanner: mapImage,
    partners: null,
  },
  {
    header: "Save N500,000 in tax penalties.",
    list: [
      "Direct Assessment",
      "State Tax Clearance",
      "State Tax Registrations",
      "Annual PAYE Returns",
      "Stay ahead of deadlines",
    ],
    cta: { text: "Get started", link: "/register" },
    sideBanner: taxClearanceImage,
    partners: null,
  },
  {
    header: "Easily apply for loans within 24hrs",
    list: ["Payroll loans", "Small business loans", "Asset-purchasing loans", "Invoice-financing loans"],
    cta: { text: "Apply now", link: "/register" },
    sideBanner: loansImage,
    partners: null,
  },
  {
    header: "Secure APIs for PAYE, Pension & Micro Pension",
    list: [
      "Straightforward and user friendly",
      "Do Payroll from your app or ERP",
      "Automated Payroll, PAYE and Pension payments",
      "For Fintechs, ERP providers and Software developers",
    ],
    cta: { text: "Check out for free", link: "/developers" },
    sideBanner: payrollApi,
    partners: null,
  },
  {
    header: "Work smarter, thrive and prosper!",
    list: ["Get amazing Deals", "Personal loans and Salary Advance", "Savings"],
    cta: { text: "Visit WorknProsper", link: "" },
    href: "https://worknprosper.com",
    sideBanner: WorknProsper,
    partners: null,
  },
  {
    header: "Remotely manage your employee devices",
    list: [
      "Easily order, track or retrieve devices",
      "Securely offboard employees and contractor",
      "One single sign-in for all employees",
    ],
    cta: { text: "See Device Management", link: "/manage-employee" },
    sideBanner: deviceMgt,
    partners: [netsLogistics, shekelMobility, pertinenceGroup],
  },
  {
    header: "Shop company assets and employee perks",
    list: ["Quality and authentic", "Flexible payment options", "Specially-curated office essentials"],
    cta: { text: "Browse our collection", link: "/hr-stores" },
    sideBanner: hrStores,
    partners: null,
  },
  {
    header: "Use our AI test solution to assess prospective employees",
    list: [
      "Access to world-class questions with full reports",
      "Designed to prevent cheating",
      "Configured against power failure",
    ],
    cta: { text: "Explore assessments", link: "/assess-employee" },
    sideBanner: assessment,
    partners: null,
  },
]);
</script>

<style lang="scss" scoped>
.bg-col {
  background-color: rgba(170, 170, 170, 0.08);
}
.nav-text {
  color: var(--New-Styles-Text-Body-dark, #515251);
  text-align: center;
  font-family: Aeonik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 1.28px;
  text-transform: uppercase;
}
.nav-cont {
  // display: flex;
  padding: 12px 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--New-Styles-Grays-Outline, #e7e8e7);
  background: #fff;
}
// .nav-style {

// }
.container {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}
.cta {
  background: rgba(17, 69, 59, 0.1);
  color: #11453b;
  font-weight: 500;
}
.whiteBg {
  background: #e7e8e7;
}
.container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
.audience {
  .bg-image {
    z-index: -1;
  }

  .bg-pattern {
    z-index: -1;
  }

  .heading {
    color: #11453b;
  }

  .cards {
    position: relative;
  }

  .card {
    background: #ffffff;
    box-shadow: 0px 13px 37px rgba(170, 170, 170, 0.21);
    border-radius: 16px;
    padding: 32px 32px;

    .card-icon {
      background: #f0f7eb;
      border-radius: 12px;
      display: grid;
      place-items: center;
      width: 60px;
      height: 60px;
      margin-bottom: 20px;
    }

    h3 {
      color: #11453b;
      font-size: 20px;
      line-height: 24px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    p {
      color: #515251;
      font-size: 14px;
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;

      &::before {
        content: "";
        width: 6px;
        height: 12px;
        border-right: 2px solid rgba(#11453b, 0.8);
        border-bottom: 2px solid rgba(#11453b, 0.8);
        transform: rotate(40deg);
        margin-right: 5px;
      }

      &::after {
        content: "";
        display: inline-block;
        width: 13px;
        height: 13px;
        position: absolute;
        left: 0;
        z-index: 0;
        background: rgba(#11453b, 0.1);
        border-radius: 1px;
      }

      & + p {
        margin-top: 10px;
      }
    }
  }

  .reach-out {
    a {
      color: #ea4e4b;
    }
  }

  @screen lg {
    .cards {
      display: flex;
      gap: 0px;
      position: relative;
    }

    .card {
      flex: 1;
      background: radial-gradient(50% 50% at 50% 50%, rgba(217, 235, 205, 0.14) 0%, rgba(217, 235, 205, 0.0266) 100%),
        #11453b;
      box-shadow: 0px 13px 20px rgba(23, 23, 23, 0.2), -11px 0px 19px rgba(23, 23, 23, 0.3);
      color: white;
      padding: 60px 50px;
      transition: 0.45s cubic-bezier(0.175, 0.585, 0.32, 1.2075);
      height: 280px;

      .card-icon {
        display: none;
      }

      h3 {
        color: white;
        font-size: 26px;
        line-height: 28px;
        margin-bottom: 24px;
      }

      p {
        font-size: 16px;
        line-height: 22px;
        color: white;

        &::after,
        &::before {
          display: none;
        }
      }
    }

    .card-1 {
      padding-right: 80px;
    }

    .card-2 {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 25%;
      width: 50%;
    }

    .card-3 {
      position: relative;
      left: 0;
    }

    .card-1:hover ~ .card-2 {
      left: 45%;
    }

    .card-2:hover {
      left: 10%;

      & ~ .card-3 {
        left: 5%;
      }
    }

    .card-3:hover {
      left: -2%;

      /* & ~ .card-2 {
        left: 27%;
      } */
    }
  }
}

@media (max-width: 768px) {
  .shrinkless {
    flex: 0 0 auto;
  }
}
</style>
