<template>
  <section id="faq" class="free-forever-section relative pt-16 pb-6 lg:py-24">
    <div class="l-container lg:grid lg:grid-cols-1 lg:items-center">
      <h2 class="text-center text-white mb-4 font-bold text-3xl capitalize">Frequently asked questions</h2>
      <Accordion />

      <div
        style="background-color: #292a29; border-radius: 40px"
        class="w-full flex flex-col gap-1 p-8 mt-8 items-center"
      >
        <img src="./assets/avatar-group.png" class="w-32" alt="" />

        <h3 class="text-white font-medium text-xl mt-9">Still got questions?</h3>
        <p class="mt-1 text-opacity-60 text-white">
          Can’t find the answer you’re looking for? Please chat to our friendly team.
        </p>

        <button
          @click="openChat"
          class="focus:outline-none rounded-full bg-mint flex items-center py-3 justify-center text-center px-3 font-medium mt-9 text-primary gap-2"
        >
          <span>Get in touch</span>
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.33203 10H14.6654"
              stroke="#11453B"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.332 6.66602L14.6654 9.99935L11.332 13.3327"
              stroke="#11453B"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import AppDownloadLinks from "./AppDownloadLinks.vue";
import Accordion from "../../components/Accordion.vue";

function openChat() {
  // Trigger the Tawk.to chat
  if (typeof Tawk_API !== "undefined") {
    Tawk_API.toggle();
  }
}
</script>

<style lang="scss" scoped>
.free-forever-section {
  background-color: #171717;
  background-image: url("./assets/pattern.png");
  background-repeat: repeat;
  background-size: cover;
  background-position: center;

  .media img {
    max-height: 500px;
    max-width: 100%;
  }

  .heading {
    // color: #292a29;

    span {
      color: #ea4e4b;
    }
  }

  @screen lg {
    .media img {
      max-width: 500px;
    }
  }
}
</style>
