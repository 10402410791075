<template>
  <main class="flex md:flex-row flex-col w-full items-center justify-center gap-10">
    <div
      v-if="currentTier > 0"
      class="bg-white w-full md:w-7/12 rounded shadow-md md:shadow-lg rounded-2xl border border-boxBorder py-4 md:p-6"
    >
      <div
        class="flex flex-col text-xl md:text-3xl text-primary-deep font-extrabold capitalize items-center px-5 md:px-10 mt-4 md:mt-0 mb-10 gap-5"
      >
        <!-- <img
          class="w-52 md:w-60"
          src="@/assets/icons/withdraw-text.svg"
          alt=""
        /> -->
        Withdraw To Bank
      </div>
      <form @submit.prevent="displayOtp" class="flex flex-col w-full" autocomplete="on">
        <div class="flex flex-col px-3 md:px-3 md:px-12 lg:px-8 xl:px-10">
          <!-- <label
            for="amount"
            class="text-left text-dark-800 text-sm font-medium"
            >Enter Amount</label
          > -->
          <easiTextInput
            placeholder="Amount"
            type="text"
            :format="true"
            name="amount"
            class="mt-1 mb-8"
            v-model="args.amount"
            required
          />

          <label for="account" class="text-dark-800 mt-3 text-left text-sm font-medium"
            >Select Destination Account
          </label>
          <easiSelectInput
            v-if="companyBankAccounts && !companyBankAccounts.length"
            :autoHeight="true"
            :addBankData="true"
            class="mt-1 mb-3"
            @update="bankName = $event"
            @showModal="addBank = true"
            :value="bankName"
          >
          </easiSelectInput>

          <easiBankSelectInput
            v-else
            :withdrawal="true"
            :data="customBankData"
            :addBankData="true"
            :beneficiary="true"
            @update="updateArgs($event)"
            @showModal="addBank = true"
            :autoHeight="true"
            :value="bankName"
            class="mt-1 mb-3"
          >
          </easiBankSelectInput>

          <div>
            <span class="capitalize text-dark-800 font-bold">Enter your pin </span>
            <span class="mb-4 w-fit flex justify-around items-center">
              <easiOtp
                class="w-full sm:w-auto"
                :type="showPin ? 'password' : 'number'"
                size="10"
                :token_length="6"
                :max-length="1"
                :error="errorRules.pin"
                @input="checkPin"
              />
              <img
                v-if="showPin"
                @click="showPin = !showPin"
                class="cursor-pointer"
                src="@/assets/icons/eye-close.svg"
              />
              <img v-else @click="showPin = !showPin" class="cursor-pointer" src="@/assets/icons/eye-open.svg" />
            </span>
          </div>
        </div>
        <div class="w-full my-3 md:justify-center md:items-center px-4 md:px-0">
          <easiButton
            :block="true"
            class="md:min-w-full md:max-w-full md:w-auto focus:outline-none text-sm py-3 bg-primary rounded-lg text-white"
          >
            Submit
          </easiButton>
        </div>
      </form>

      <easiContainer :noBg="true" class="w-full pb-8 mx-auto -mt-6 text-center" v-show="showOtp">
        <div class="mb-4">
          <div
            class="flex flex-col text-primary-deep text-2xl md:text-3xl font-extrabold items-center px-5 md:px-10 mb-4 gap-5"
          >
            <!-- <img
              class="w-52 md:w-60"
              src="@/assets/icons/otp-text.svg"
              alt=""
            /> -->
            OTP Verification
          </div>
          <span class="text-xs md:text-md mt-1 font-medium block"> Please input OTP sent to your email </span>
        </div>
        <span ref="otpItem" class="mb-4 flex justify-around items-center">
          <easiOtp
            size="10"
            :token_length="6"
            :max-length="1"
            v-model="otpCode"
            @input="checkOTP"
            :error="errorRules.otpCode"
          />
        </span>
        <div class="text-secondary justify-center text-sm flex items-center gap-1">
          <div class="w-9 h-9 rounded-xl text-sm flex justify-center items-center bg-background">
            {{ minutes }}
          </div>
          :
          <div class="w-9 h-9 rounded-xl text-sm flex justify-center items-center bg-background">
            {{ seconds }}
          </div>
        </div>
        <div class="text-dark-800 mb-1 text-xs font-medium text-center flex items-center justify-center">
          Did not receive OTP?
          <easiButton
            @click="sendOTP('EMAIL')"
            type="button"
            variant="text"
            color="secondary"
            class="text-xs px-0"
            :class="disabledBtn ? 'cursor-not-allowed' : undefined"
            :disabled="disabledBtn"
            >Resend</easiButton
          >
        </div>

        <div class="text-dark-800 mb-4 text-xs font-medium text-center flex items-center justify-center">
          <easiButton
            @click="sendOTP('SMS')"
            type="button"
            variant="text"
            color="secondary"
            class="text-xs px-0"
            :class="disabledBtn ? 'cursor-not-allowed' : undefined"
            :disabled="disabledBtn"
            >Send to phone number instead</easiButton
          >
        </div>

        <div class="w-full md:justify-center md:items-center px-4 md:px-0">
          <easiButton
            @click="confirmOtp"
            type="button"
            :block="true"
            class="md:min-w-full md:max-w-full md:w-auto focus:outline-none text-sm py-3 bg-primary rounded-full text-white"
            :disabled="otpCode && otpCode.length != 6"
          >
            Withdraw to Bank
          </easiButton>
        </div>
      </easiContainer>
    </div>

    <div
      v-else-if="currentTier == 0"
      class="bg-white w-full md:w-7/12 rounded shadow-md md:shadow-lg rounded-2xl border border-boxBorder py-4 md:p-6"
    >
      <div class="flex flex-col w-full px-4 md:px-5 gap-7 items-center">
        <div class="flex flex-col justify-center self-center mt-6 items-center">
          <img class="w-20" :src="ineligibleIcon" alt="" />
        </div>
        <span class="text-dark-800 text-lg text-center"
          >Kindly complete KYC Tier 1 to get your virtual account details.</span
        >
        <div class="w-full flex flex-col gap-y-8">
          <!-- <easiTextInput
            required
            class="max-w-full w-full min-w-full"
            type="number"
            v-model="bvnNumber"
            placeholder="Enter your BVN"
          ></easiTextInput> -->
          <easiButton
            @click="$router.push({ name: 'Profile' })"
            :block="true"
            color="primary"
            type="submit"
            class="md:min-w-full md:max-w-full md:w-auto w-full focus:outline-none text-sm py-3 bg-primary rounded-lg text-white"
          >
            Continue
          </easiButton>
        </div>
      </div>
    </div>

    <easiModal v-if="addBank" @close="addBank = false" :isHeader="false">
      <BankDetail @close="closeBankModal" user="admin" :type="true" />
    </easiModal>

    <easiRoundLoader v-if="loading" />

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Money sent to your bank account</span>
      </template>
    </easiSuccess>

    <easiEligible v-if="ineligible" headerMessage="BVN Verification in Progress..." @close="ineligible = false">
      <template v-slot:message>
        <!-- <span class="text-center mb-4">
          <strong>BVN Verification in Progress...</strong>
        </span> -->

        <span class="leading-5 text-center"
          >To speed up this process, kindly send the photo of your valid ID, a passport image, and your proof of
          residential address (e.g utility bill) to compliance@myeazipay.com.</span
        >
      </template>

      <template v-slot:action>
        <div class="px-4 sm:px-10 lg:px-6 xl:px-8">
          <button
            @click="closeComp"
            class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
          >
            Back To Wallet
          </button>
        </div></template
      >
    </easiEligible>
  </main>
</template>

<script setup>
import { ref, reactive, onMounted, nextTick, watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";
import BankDetail from "@/components/Employee/Edit/Benefits/BankDetails.vue";
import banks from "@/utils/banks";
import ineligibleIcon from "@/assets/icons/empty-state.svg";

const toast = useToast();
const store = useDataStore();

const { query, mutate } = store;

const props = defineProps({
  adminEmail: {
    type: String,
    default: "",
  },
  adminPhoneNumber: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["close"]);

const { formatAmount, calculatePercentage } = helperFunctions;
const companyBankAccounts = computed(() => store.getAllBanks);
const fullCompanyData = computed(() => store.getCompanyDetails);
const currentTier = computed(() => {
  if (fullCompanyData.value && fullCompanyData.value.tier) {
    return Number(fullCompanyData.value.tier);
  }
  return 0;
});

const minutes = ref(2);
const seconds = ref("00");
const timer = ref(120);
const disabledBtn = ref(false);

function startTimer() {
  disabledBtn.value = true;
  const interval = setInterval(function () {
    minutes.value = parseInt(timer.value / 60, 10);
    seconds.value = parseInt(timer.value % 60, 10);

    minutes.value = minutes.value < 10 ? "0" + minutes.value : minutes.value;
    seconds.value = seconds.value < 10 ? "0" + seconds.value : seconds.value;

    if (--timer.value < 0) {
      timer.value = 120;
      disabledBtn.value = false;
      clearInterval(interval);
    }
  }, 1000);
}

const enterOtp = ref(false);
const addBank = ref(false);
const otpItem = ref(null);
const bvnNumber = ref("");

const updateSuccess = ref(false);
const loading = ref(false);
const showPin = ref(false);
const showOtp = ref(false);
const bankName = ref("");
const pin = ref("");
const otpCode = ref("");
const otpReference = ref("");

const argsArray = ref([]);
const customBankData = ref([]);
const errorRules = reactive({
  pin: false,
  confirmPin: false,
  referralCode: false,
  otpCode: false,
});

const args = ref({
  accountName: "",
  accountNumber: "",
  bankCode: "",
  amount: 0,
  options: {
    type: "wema",
  },
});
const bankTransfer = ref(true);
const accountNumber = ref("0711456765");
const link = ref(
  "9DIJxLyx3H85tP87hYF8eg/EAZIPAY-NEW-WEB-APP-PROJECT?node-id=6150%3EAZIPAY-NEW-WEB-Flutterwaveaccount7."
);
const topupOption = ref(false);
const payStack = ref(false);
const linkGenerated = ref(false);
const shareLink = ref(false);
const transferDetails = ref(false);
const transferNotification = ref(false);
function copyText(val) {
  var text = val;
  navigator.clipboard.writeText(text);
  toast.info("Copied!");
}
const payrollInterval = ref(["Weekly", "Monthly"]);

async function displayOtp() {
  if (pin.value.length) {
    showOtp.value = true;

    await nextTick(() => otpItem.value.scrollIntoView());
    // window.scrollTo({ top: 100000, behavior: "smooth" });

    await sendOTP();
  } else {
    errorRules.pin = "Pin is required";
  }
}

async function queryBankAccounts() {
  loading.value = true;
  try {
    await query({
      endpoint: "FetchBankAccounts",
      service: "SETTINGS",
      storeKey: "allBanks",
    });
    const data = companyBankAccounts.value
      ? companyBankAccounts.value.map((bank) => {
          return {
            name: bank.bankName,
            code: bank.bankCode,
            logo: bankUrl(bank.bankName).logo,
            accountName: bank.accountName,
            accountNumber: bank.accountNumber,
          };
        })
      : [];
    customBankData.value = data;

    loading.value = false;
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
}
queryBankAccounts();

watch(companyBankAccounts, (val) => {
  const data =
    val && val.length
      ? val.map((bank) => {
          return {
            name: bank.bankName,
            code: bank.bankCode,
            logo: bankUrl(bank.bankName).logo,
            accountName: bank.accountName,
            accountNumber: bank.accountNumber,
          };
        })
      : [];
  customBankData.value = data;
});

async function closeBankModal() {
  await queryBankAccounts();
  addBank.value = false;
}

const bankUrl = (name) => {
  if (name) {
    const data = banks.filter((x) => {
      return name.toLowerCase().indexOf(x.name.toLowerCase()) > -1 ? x : "";
    });
    return data[0];
  } else {
    return {
      logo: "",
    };
  }
};

function updateArgs(e) {
  bankName.value = e.name;
  args.value.accountName = e.accountName;
  args.value.accountNumber = e.accountNumber;
  args.value.bankCode = e.code;
}

const withdrawToBank = async () => {
  args.value.amount = Number(args.value.amount);
  loading.value = true;

  try {
    let res = await mutate({
      endpoint: "Withdraw",
      data: { input: args.value },
      service: "PAYROLL",
    });
    console.log(res, "RES");
    if (res.success) {
      updateSuccess.value = true;
      loading.value = false;
    } else {
      toast.error(res.message);
    }
  } catch (e) {
    loading.value = false;
    toast.error(e.message);
    console.log(e);
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  // queryBankAccounts();
  console.log(companyBankAccounts.value);
});

function generatePaymentLink() {
  payStack.value = true;
}

function checkOTP(otp_input) {
  otpCode.value = otp_input;
}

function checkPin(otp_input) {
  pin.value = otp_input;
}

function addMore(arg) {
  const newObj = Object.assign({}, args);

  argsArray.value.push(newObj);

  store.$patch((state) => {
    state.profileArr.push(newObj);
  });
  toast.success("Details saved!");
}
const ineligible = ref(false);
function closeComp() {
  ineligible.value = false;
  emit("close");
}

async function sendOTP(via) {
  //validate form field
  // if (
  //   fullCompanyData.value &&
  //   fullCompanyData.value.bvn &&
  //   fullCompanyData.value.bvnVerified == false
  // ) {
  //   ineligible.value = true;
  //   return;
  // }
  const payload = {
    channel: via && typeof via === "string" ? via : "EMAIL",
    value:
      via && typeof via == "string" && via === "SMS"
        ? props.adminEmail // props.adminPhoneNumber changed from phoneNumber to email but keeping the code in case it's later changed to phone number
        : props.adminEmail,
    type: "WITHDRAWAL_OTP",
  };

  loading.value = true;

  try {
    let res = await mutate({
      endpoint: "SendOTP",
      data: { input: payload },
      service: "AUTH",
    });
    otpReference.value = res.data.reference;

    if (res.success === true) {
      startTimer();
      enterOtp.value = true;
      // toast.success("OTP")
      // window.localStorage.setItem("otpReference", res.data.reference);
    }
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
}

async function confirmOtp() {
  if (otpCode.value.trim() === "") {
    errorRules.otpCode = "Please enter the otp code sent to your email";
  } else {
    loading.value = true;

    const payload = {
      reference: otpReference.value,
      code: otpCode.value,
      value: props.adminEmail,
    };

    try {
      let res = await mutate({
        endpoint: "ConfirmOTP",
        data: { input: payload },
        service: "AUTH",
      });

      if (res.success) {
        //Clear error state
        errorRules.otpCode = false;

        //Register user
        await withdrawToBank();
        // enterOtp.value = false;
      } else {
        errorRules.otpCode = res.message;
      }
    } catch (e) {
      e.message.toLowerCase() == "record not found"
        ? (errorRules.otpCode = "OTP doesn't match. Please try again")
        : null;
    } finally {
      loading.value = false;
    }
  }
}

const queryCompany = async () => {
  try {
    await query({
      endpoint: "FetchCompaniesByAdminId",
      service: "SETTINGS",
      storeKey: "companyDetails",
    });
  } catch (e) {
    console.log(e);
  }
};

queryCompany();

onMounted(async () => {
  await queryCompany();
});

async function save() {
  addMore(args);
  Object.assign(args, resetObj(args, "args"));
}

async function addBvn(endpoint) {
  loading.value = true;

  try {
    let res = await mutate({
      endpoint,
      data: { input: { bvn: bvnNumber.value } },
      service: "SETTINGS",
    });
    if (res.success) {
      await queryCompany();
      await queryCompanyAccountDetails();
    } else {
      toast.error(res.message);
    }
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
}

async function submitBvn() {
  try {
    if (bvnNumber.value.length !== 11) {
      toast.error("BVN length must be 11 characters");
      return false;
    }
    if (fullCompanyData.value) {
      if (fullCompanyData.value.registrationType === "COMPANY") {
        await addBvn("CreateAccountInformation");
        return;
      }
      await addBvn("CreateAccountInformation");
    }
  } catch (e) {
    console.log(e);
  }
}

async function queryCompanyAccountDetails() {
  try {
    await query({
      endpoint: "ViewCompanyVirtualAccount",
      service: "AUTH",
      storeKey: "companyAccountDetails",
    });
  } catch (e) {
    console.log(e);
  }
}

onMounted(async () => {
  await queryCompanyAccountDetails();
});
</script>

<style></style>
