<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="#515251" />
    <path
      d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM5.84688 16C5.84688 21.6074 10.3926 26.1531 16 26.1531C21.6074 26.1531 26.1531 21.6074 26.1531 16C26.1531 10.3926 21.6074 5.84688 16 5.84688C10.3926 5.84688 5.84688 10.3926 5.84688 16Z"
      fill="#F2F1F1"
    />
    <path
      d="M16 4C9.37256 4 4 9.37256 4 16C4 22.6274 9.37256 28 16 28C22.6274 28 28 22.6274 28 16C28 9.37256 22.6274 4 16 4ZM20.578 10.1111L18.9183 10.1118C17.6168 10.1118 17.3651 10.7303 17.3651 11.6378V13.6388H20.4688L20.4673 16.7729H17.3654V24.8158H14.1281V16.7729H11.422V13.6388H14.1281V11.3274C14.1281 8.64507 15.767 7.1842 18.1596 7.1842L20.5783 7.18789V10.1111H20.578Z"
      fill="#F2F1F1"
    />
  </svg>
</template>
